import {
  faAddressCard,
  faBox,
  faFont,
  faInfo,
  faLanguage,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";

import userUtil from "../../../../../../../utils/user.util";

import { useLanguage } from "../../../../../../language.context";
import ButtonMenuControl from "../../../../../../controls/buttonmenu.control";

import { useAppState } from "../../../../appState.context";

import EnterEmailControl from "../../../recomendations.button/recomendations.modal/enteremail.control";

import EditNameControl from "./editname.control";
import LanguageControl from "./editlanguage.control";
import DeleteAccountControl from "./deleteaccount.control";
import LogoutControl from "./logout.control";
import InfoControl from "./info.control";

export default ({ ctx }) => {
  const languageCtx = useLanguage();
  const appStateCtx = useAppState();

  const items = [
    {
      titleLanguageStringId: languageCtx.getIdFromId("Info"),
      icon: faInfo,
      onClick: () => {
        ctx.pushItem(languageCtx.getIdFromId("Info"), (ctx) => (
          <InfoControl ctx={ctx} />
        ));
      },
    },
    {
      titleLanguageStringId: languageCtx.getIdFromId("editName"),
      icon: faFont,
      onClick: () => {
        ctx.pushItem(languageCtx.getIdFromId("editName"), (ctx) => (
          <EditNameControl ctx={ctx} />
        ));
      },
    },
    {
      titleLanguageStringId: languageCtx.getIdFromId("editLanguage"),
      icon: faLanguage,
      onClick: () => {
        ctx.pushItem(languageCtx.getIdFromId("editLanguage"), (ctx) => (
          <LanguageControl ctx={ctx} />
        ));
      },
    },
    {
      titleLanguageStringId: languageCtx.getIdFromId("deleteAccount"),
      icon: faBox,
      onClick: () => {
        ctx.pushItem(languageCtx.getIdFromId("deleteAccount"), (ctx) => (
          <DeleteAccountControl ctx={ctx} />
        ));
      },
    },
    {
      titleLanguageStringId: languageCtx.getIdFromId("Register"),
      icon: faAddressCard,
      onClick: () => {
        ctx.pushItem(languageCtx.getIdFromId("Register"), (ctx) => (
          <EnterEmailControl ctx={ctx} />
        ));
      },
      visible: () => !userUtil.isRegistered(appStateCtx.user.get()),
    },
    {
      titleLanguageStringId: languageCtx.getIdFromId("logout"),
      icon: faRightFromBracket,
      onClick: () => {
        ctx.pushItem(languageCtx.getIdFromId("logout"), (ctx) => (
          <LogoutControl ctx={ctx} />
        ));
      },
    },
  ];

  return <ButtonMenuControl items={items} />;
};
