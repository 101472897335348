import React, { createContext, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";

import ApiServerAffiliateClient from "../../../utils/apiserver.affiliate.client";
import localStorageUtil from "../../../utils/localStorage.util";

import HeaderControl from "../../controls/header.control";
import { useException } from "../../exception.context";
import { useLanguage } from "../../language.context";

const apiServerClient = new ApiServerAffiliateClient();
const appContext = createContext();

export const useAffiliateAppState = () => {
  return useContext(appContext);
};

export const AffiliateAppStateContextProvider = ({ children }) => {
  const navigate = useNavigate();

  const exceptionCtx = useException();
  const languageCtx = useLanguage();

  const [affiliate, setAffiliate] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const handleGotoClicked = (url) => {
    navigate(url);
  };

  const redirect = (affiliate) => {
    // if (window.location.pathname === "/app/payment") {
    //   return;
    // }

    if (affiliate) {
      if (window.location.pathname === "/affiliate/auth") {
        navigate(`/affiliate/welcome`);
      }
    } else {
      if (window.location.pathname !== "/affiliate/auth") {
        navigate(`/affiliate/auth`);
      }
    }
  };

  useEffect(() => {
    const refresh = async () => {
      try {
        setIsLoading(true);
        const token = loadToken();

        const affiliate = await loadAffiliateFromToken(token);
        setAffiliate(affiliate);
        redirect(affiliate);
      } catch (ex) {
        //exceptionCtx.handleException(ex);
      } finally {
        setIsLoading(false);
      }
    };

    refresh();
  }, []);

  useEffect(() => {
    const refresh = async () => {
      try {
        if (affiliate && languageCtx.language) {
          if (languageCtx.language.code !== affiliate.language) {
            const affiliate = await apiServerClient.affiliate.updateLanguage(
              languageCtx.language.code
            );

            setAffiliate(affiliate);
          }
        }
      } catch (ex) {}
    };
    refresh();
  }, [languageCtx.language]);

  // user

  const loadAffiliateFromToken = async (token) => {
    if (!token) {
      return null;
    }

    const affiliate = await apiServerClient.affiliate.signinByToken(
      token,
      languageCtx.language.code
    );

    return affiliate;
  };

  const loadToken = () => {
    try {
      const token = localStorageUtil.get("affiliatetoken");

      return token;
    } catch (ex) {
      return null;
    }
  };

  const saveToken = (token) => {
    if (!token) {
      localStorageUtil.remove("affiliatetoken");
    } else {
      localStorageUtil.set("affiliatetoken", token);
    }
  };

  const signin = async (email, password) => {
    const affiliate = await apiServerClient.affiliate.signin(
      email,
      password,
      languageCtx.language.code
    );

    saveToken(affiliate?.token);

    setAffiliate(affiliate);

    return affiliate;
  };

  const initialValue = {
    affiliate: {
      get: () => affiliate,
      signin,
      //signout,
    },
    apiServerClient,
  };

  const handleLogoutClicked = (e) => {
    e.preventDefault();
    saveToken();
    setAffiliate();
    navigate("/");
  };

  if (isLoading) return null;

  return (
    <>
      <HeaderControl>
        {affiliate && (
          <Nav.Link
            onClick={() => handleGotoClicked("/affiliate/welcome")}
            className="fw-500"
          >
            {languageCtx.getStringFromId("Welcome")}
          </Nav.Link>
        )}
        {affiliate && (
          <Nav.Link
            onClick={() => handleGotoClicked("/affiliate/giftcards")}
            className="fw-500"
          >
            {languageCtx.getStringFromId("Giftcards")}
          </Nav.Link>
        )}
        {affiliate && (
          <Nav.Link
            onClick={() => handleGotoClicked("/affiliate/giftcards/charges")}
            className="fw-500"
          >
            {languageCtx.getStringFromId("Current month")}
          </Nav.Link>
        )}
        {affiliate && (
          <Nav.Link
            onClick={() => handleGotoClicked("/affiliate/giftcards/invoices")}
            className="fw-500"
          >
            {languageCtx.getStringFromId("Invoices")}
          </Nav.Link>
        )}
        {affiliate && (
          <a
            href=""
            onClick={handleLogoutClicked}
            className="btn btn-blue"
            append="1"
          >
            {languageCtx.getStringFromId("logout")}
          </a>
        )}
      </HeaderControl>
      <appContext.Provider value={initialValue}>{children}</appContext.Provider>
    </>
  );
};
