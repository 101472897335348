import React, { createContext, useContext } from "react";

import stringUtil from "../utils/string.util";
import errorUtil from "../utils/error.util";
import errorbaseUtil from "../utils/errorbase.util";

import { useMessage } from "./message.context";
import { useLanguage } from "./language.context";

const ErrorContext = createContext(null);

export const useException = () => {
  return useContext(ErrorContext);
};

export const messageStates = {
  success: 1,
  error: 2,
  info: 3,
  warning: 4,
};

// const isServerError = (ex) => {
//   if (!ex?.error) return false;
//   return true;
// };

export const ExceptionContextProvider = ({ children }) => {
  const messageCtx = useMessage();
  const languageCtx = useLanguage();

  const handleException = (ex) => {
    errorbaseUtil.insertException(ex);

    if (errorUtil.isCustomError(ex)) {
      // if (ex.error.id === "SERVER_USER_NOT_AUTHENTICATED") {
      //   // force goto login page
      //   appStateCtx.user.signout();
      // }

      let error = languageCtx.getErrorFromId(ex.error.id);

      if (ex.error.params) {
        error = stringUtil.formatString(error, ...ex.error.params);
      }

      messageCtx.showError(error);
    } else {
      messageCtx.showError(ex.toString());
    }
  };

  return (
    <ErrorContext.Provider value={{ handleException }}>
      {children}
    </ErrorContext.Provider>
  );
};
