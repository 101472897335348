import { useEffect, useState } from "react";

import { useLanguage } from "../../../../../language.context";
import { useException } from "../../../../../exception.context";
import { useMessage } from "../../../../../message.context";
import CheckButtonsControl from "../../../../../controls/checkbuttons.control";

import { MediasOrderBys, useAppState } from "../../../appState.context";

export default ({ ctx }) => {
  const languageCtx = useLanguage();
  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const items = [
    { text: languageCtx.getStringFromId("name"), id: MediasOrderBys.name },
    { text: languageCtx.getStringFromId("date"), id: MediasOrderBys.date },
  ];

  useEffect(() => {
    const index = items.findIndex((i) => i.id === appStateCtx.mediasOrderBy);
    setSelectedIndex(index);
  }, [appStateCtx.mediasOrderBy]);

  const handleSavePressed = async () => {
    try {
      await appStateCtx.setMediasOrderBy(items[selectedIndex].id);

      messageCtx.showSuccess(
        languageCtx.getStringFromId("Photos order by changed")
      );
      ctx.popItem();
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  return (
    <div className="text-center p-4">
      <div className="fs-2">{languageCtx.getStringFromId("orderBy")}</div>
      <CheckButtonsControl
        className="mt-4"
        theme="blue"
        items={items}
        selectedIndex={selectedIndex}
        style={{ marginHorizontal: 20, marginTop: 10 }}
        setSelectedIndex={setSelectedIndex}
      />
      <div className="mt-3">
        <button onClick={handleSavePressed} className="btn btn-blue mt-4">
          {languageCtx.getStringFromId("Save")}
        </button>
      </div>
    </div>
  );
};
