import { useEffect, useState } from "react";

import { useLanguage } from "../../../../../language.context";
import { useAppState } from "../../../appState.context";

export default ({ ctx }) => {
  const appStateCtx = useAppState();
  const languageCtx = useLanguage();

  const [privacyPolicy, setPrivacyPolicy] = useState();

  const refresh = async () => {
    try {
      const privacyPolicy =
        await appStateCtx.apiServerClient.privacyPolicy.getById(
          appStateCtx.user.get().privacyPolicy._id
        );

      setPrivacyPolicy(privacyPolicy);
    } catch (ex) {
      console.error(ex);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  if (!privacyPolicy) return null;

  return (
    <div className="p-4">
      <div className="fs-2 lh-sm">
        {languageCtx.getStringFromId(
          "fotisimaCommitsToSecurityAndTransparency"
        )}
      </div>
      <div className="mt-3">
        <span>
          {languageCtx.getStringFromId(
            "whenYouUseFotisimaYoyAreTrustingUsWithInformation"
          )}
        </span>{" "}
        <a
          href={privacyPolicy.url}
          target="_blank"
          referrerPolicy="no-referrer"
        >
          {languageCtx.getStringFromId("privacyPolicy")}
        </a>{" "}
        <span>
          {languageCtx.getStringFromId(
            "isMeantToHelpYouUnderstandWhatInformationWeCollect"
          )}
        </span>
      </div>
    </div>
  );
};
