import React, { createContext, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import { DateTime } from "luxon";

import ApiServerClient from "../../../utils/apiserver.user.client";
import localStorageUtil from "../../../utils/localStorage.util";
import stringUtil from "../../../utils/string.util";
import userUtil from "../../../utils/user.util";

import { useLanguage } from "../../language.context";
import { useMessage } from "../../message.context";
import { useException } from "../../exception.context";

const apiServerClient = new ApiServerClient();
const appContext = createContext();

export const useAppState = () => {
  return useContext(appContext);
};

export const MediasOrderBys = {
  name: "name",
  date: "date",
};

export const AppStateContextProvider = ({ children }) => {
  const navigate = useNavigate();

  const languageCtx = useLanguage();
  const messageCtx = useMessage();
  const exceptionCtx = useException();

  const [albumWelcomeMessageShown, setAlbumWelcomeMessageShown] =
    useState(true);
  const [isLoadingAlbum, setIsLoadingAlbum] = useState(true);
  const [mediasOrderBy, setMediasOrderBy] = useState(MediasOrderBys.name);
  const [deviceId, setDeviceId] = useState("Browser");
  const [user, setUser] = useState();
  const [albums, setAlbums] = useState([]);
  const [album, setAlbum] = useState();
  const [medias, setMedias] = useState([]);
  const [mediasTimestamp, setMediasTimestamp] = useState(
    DateTime.now().toISO()
  );
  const [isLoadingMedias, setIsLoadingMedias] = useState(true);
  const [hideAddToHomeScreenButton, setHideAddToHomeScreenButton] =
    useState(true);

  useEffect(() => {
    const refresh = async () => {
      try {
        const deviceId = loadDeviceId();

        const hideAddToHomeScreenButton = loadHideAddToHomeScreenButton();

        const token = loadToken();

        let user;

        try {
          user = await loadUserFromToken(token);
        } catch {}

        let albums, album;
        try {
          const albumId =
            window.location.pathname.startsWith("/app/albums/") &&
            window.location.pathname.replace("/app/albums/", "");

          ({ albums, album } = await loadAlbumsAlbumFromUser(user, [
            albumId,
            loadAlbumId(user),
          ]));
        } catch {}

        setAndSaveHideAddToHomeScreenButton(hideAddToHomeScreenButton);
        setAndSaveDeviceId(deviceId);
        setUser(user);
        setAlbums(albums);
        setAlbumAndSaveToLocalStorage(user, album);

        if (user) {
          if (!window.location.pathname.startsWith("/app/payment")) {
            messageCtx.showSuccess(
              stringUtil.formatString(
                languageCtx.getStringFromId("Welcome xxx"),
                user.name
              )
            );
          }
        }

        // if (window.location.pathname.startsWith("/app")) {
        //   redirect(user, album);
        // }
      } catch (ex) {
        exceptionCtx.handleException(ex);
      } finally {
        setIsLoadingAlbum(false);
      }
    };

    refresh();
  }, []);

  useEffect(() => {
    if (!album) return;

    const mediasOrderBy = loadMediasOrderBy(user);

    setAndSaveMediasOrderBy(mediasOrderBy);
  }, [user]);

  useEffect(() => {
    if (!album) return;

    const albumWelcomeMessageShown = loadAlbumWelcomeMessageShown(user, album);
    saveAndSetWelcomeMessageShown(albumWelcomeMessageShown);
  }, [album?._id]);

  useEffect(() => {
    const refreshMedias = async (album2) => {
      const info = await loadMediasFromAlbumId(album2?._id);

      setMedias(info.medias);
      setMediasTimestamp(info.timestamp);
    };

    refreshMedias(album);
  }, [album?._id, user]);

  useEffect(() => {
    const refresh = async () => {
      try {
        if (user && languageCtx.language) {
          if (languageCtx.language.code !== user.language) {
            const user = await apiServerClient.user.updateLanguage(
              languageCtx.language.code
            );

            setUser(user);
          }
        }
      } catch (ex) {}
    };
    refresh();
  }, [languageCtx.language]);

  const loadAlbumWelcomeMessageShown = (user, album) => {
    try {
      const shown =
        localStorageUtil.get(
          `${user._id}.albums.${album._id}.welcomeMessageShown`
        ) === "1" || false;

      return shown;
    } catch {
      return false;
    }
  };

  const saveWelcomeMessageShown = (shown) => {
    try {
      localStorageUtil.set(
        `${user._id}.albums.${album._id}.welcomeMessageShown`,
        shown ? "1" : "0"
      );
    } catch (ex) {
      console.error(ex);
    }
  };

  const saveAndSetWelcomeMessageShown = (shown) => {
    saveWelcomeMessageShown(shown);
    setAlbumWelcomeMessageShown(shown);
  };

  const markAlbumWelcomeMessageShown = async () => {
    saveAndSetWelcomeMessageShown(true);
  };

  const loadHideAddToHomeScreenButton = () => {
    let hideButton = true;
    try {
      hideButton = localStorageUtil.get(`hideAddToHomeScreenButton`) === "1";
    } catch (ex) {}
    return hideButton;
  };

  const saveHideAddToHomeScreenButton = (hideButton) => {
    try {
      localStorageUtil.set(`hideAddToHomeScreenButton`, hideButton ? "1" : "0");
    } catch (ex) {
      console.error(ex);
    }
  };

  const loadMediasOrderBy = () => {
    let orderBy;
    try {
      orderBy =
        localStorageUtil.get(`${user._id}.medias.orderBy`) ||
        MediasOrderBys.name;
    } catch (ex) {
      orderBy = MediasOrderBys.name;
    }
    return orderBy;
  };

  const saveMediasOrderBy = (orderBy) => {
    try {
      localStorageUtil.set(`${user._id}.medias.orderBy`, orderBy);
    } catch (ex) {
      console.error(ex);
    }
  };

  const setAndSaveMediasOrderBy = (orderBy) => {
    saveMediasOrderBy(orderBy);
    setMediasOrderBy(orderBy);
  };

  const setAndSaveHideAddToHomeScreenButton = (hideButton) => {
    saveHideAddToHomeScreenButton(hideButton);
    setHideAddToHomeScreenButton(hideButton);
  };

  const loadDeviceId = () => {
    let uuid;

    try {
      uuid = localStorageUtil.get("deviceId") || "Browser";
    } catch {
      uuid = "Browser";
    }

    return uuid;
  };

  const setAndSaveDeviceId = (deviceId) => {
    saveDeviceId(deviceId);
    setDeviceId(deviceId);
  };

  const saveDeviceId = (deviceId) => {
    try {
      localStorageUtil.set("deviceId", deviceId);
    } catch {}
  };

  const joinAlbum = async (albumId) => {
    const album = await apiServerClient.album.join(albumId);

    const albums2 = [...albums];

    if (!albums2.find((a) => a._id === album._id)) {
      albums2.push(album);
    }

    setAlbums(albums2);
    setAlbumAndSaveToLocalStorage(user, album);
  };

  const loadToken = () => {
    try {
      const token = localStorageUtil.get("token");

      return token;
    } catch (ex) {
      return null;
    }
  };

  const saveToken = (token) => {
    if (!token) {
      localStorageUtil.remove("token");
    } else {
      localStorageUtil.set("token", token);
    }
  };

  const redirect = (user, album) => {
    if (window.location.pathname === "/app/payment") {
      return;
    }
    if (user) {
      if (album) {
        navigate(`/app/albums/${album._id}`);
      } else {
        navigate(`/app/albums`);
      }
    } else {
      navigate(`/app/auth/enteremail`);
    }
  };

  const loadAlbumsAlbumFromUser = async (user, albumIds) => {
    if (!user) {
      return { albums: [] };
    }

    const albums = await apiServerClient.album.getAll();

    let album;
    for (var albumId of albumIds.filter((i) => i)) {
      album = albums.find((a) => a._id === albumId);

      if (album) break;
    }

    if (!album) {
      if (albums.length) {
        // get non demo open album if exist
        album = albums.find(
          (a) => a.status.started && !a.status.ended && !a.isDemo
        );

        if (!album) {
          // get any open album if exist
          album = albums.find((a) => a.status.started && !a.status.ended);

          if (!album) {
            // get first album
            album = albums[0];
          }
        }
      }
    }

    albums.sort((a, b) => a.name.localeCompare(b.name));

    return { albums, album };
  };

  const loadMediasFromAlbumId = async (albumId) => {
    if (!albumId) {
      return { medias: [], timestamp: DateTime.now().toISO() };
    }

    setMedias([]);
    setIsLoadingMedias(true);

    const info = await apiServerClient.media.getByAlbumId(albumId);

    setIsLoadingMedias(false);

    return info;
  };

  const refreshMediasFromChanges = async () => {
    if (!userUtil.isUser(user)) return;

    try {
      const info = await loadMediaChangesFromAlbumId(
        album?._id,
        mediasTimestamp
      );

      let medias2 = [...medias];

      for (var media of info.medias) {
        medias2 = medias2.filter((m) => m._id !== media._id);
        medias2.push(media);
      }

      for (var deletedMedia of info.deletedMedias) {
        medias2 = medias2.filter((m) => m._id !== deletedMedia._id);
      }

      setMedias(medias2);
      setMediasTimestamp(info.timestamp);
    } catch (ex) {
      console.error(ex);
      // not sure why we get here, but we do
    }
  };

  const loadMediaChangesFromAlbumId = async (albumId, timestamp) => {
    if (!albumId) {
      return {
        medias: [],
        deletedMedias: [],
        timestamp: DateTime.now().toISO(),
      };
    }

    const info = await apiServerClient.media.getChangesByAlbumId(
      albumId,
      timestamp
    );

    return info;
  };

  const loadUserFromToken = async (token) => {
    if (!token) {
      return null;
    }

    const user = await apiServerClient.user.signinByToken(token);

    return user;
  };

  // user

  const deleteUser = async () => {
    await apiServerClient.user.delete();
    setUser();

    setAlbums([]);
    setAlbumAndSaveToLocalStorage();
    setMedias([]);
    navigate("/");
  };

  const updateUserName = async (name) => {
    const user = await apiServerClient.user.updateName(name);
    setUser(user);

    const medias2 = [...medias];

    for (var media of medias2) {
      if (media.ownerUserId === user._id) {
        media.ownerUser = user;
        media.ownerUserId = user._id;
      }
    }

    setMedias(medias2);
  };

  const signup = async (
    name,
    email,
    verificationCode,
    privacyPolicyId,
    termsOfServiceId
  ) => {
    const user = await apiServerClient.user.signup(
      name,
      email,
      verificationCode,
      languageCtx.language.code,
      privacyPolicyId,
      termsOfServiceId
    );

    saveToken(user.token);

    const albums = [],
      album = undefined,
      medias = [];

    setUser(user);
    setAlbums(albums);
    setAlbumAndSaveToLocalStorage(user, album);
    setMedias(medias);
    redirect(user, album);

    return { user };
  };

  const signupAsGuestAndJoin = async (
    name,
    albumId,
    privacyPolicyId,
    termsOfServiceId
  ) => {
    const user = await apiServerClient.user.signupAsGuest(
      name,
      languageCtx.language.code,
      privacyPolicyId,
      termsOfServiceId
    );

    saveToken(user.token);

    await apiServerClient.album.join(albumId);

    const { albums, album } = await loadAlbumsAlbumFromUser(user, [albumId]);

    setUser(user);
    setAlbums(albums);
    setAlbumAndSaveToLocalStorage(user, album);

    return { user, albums, album };
  };

  const registerGuest = async (email, verificationCode, languageCode) => {
    const user2 = await apiServerClient.user.registerGuest(
      user._id,
      email,
      verificationCode,
      languageCode
    );

    saveToken(user2.token);

    const { albums, album: album2 } = await loadAlbumsAlbumFromUser(user2, [
      album?._id,
    ]);

    setUser(user2);
    setAlbums(albums);
    setAlbumAndSaveToLocalStorage(user2, album2);
  };

  const signinByEmailAndVerificationCode = async (email, verificationCode) => {
    const user = await apiServerClient.user.signinByEmailAndVerificationCode(
      email,
      verificationCode
    );

    saveToken(user?.token);

    const { albums, album } = await loadAlbumsAlbumFromUser(user, [
      loadAlbumId(user),
    ]);

    setUser(user);
    setAlbums(albums);
    setAlbumAndSaveToLocalStorage(user, album);

    return { user, albums, album };
  };

  const signout = async () => {
    saveToken();
    await apiServerClient.user.signout();
    setUser();
    setAlbums([]);
    setAlbumAndSaveToLocalStorage();
    setMedias([]);
    // TODO reset history, so we cannot go back to album and log in as guest
    navigate("/");
  };

  // medias

  const deleteMedia = async (mediaId) => {
    const album = await apiServerClient.media.delete(mediaId);

    updateAlbums(album);

    const medias2 = medias.filter((m) => m._id !== mediaId);
    setMedias(medias2);
  };

  // album

  const setAlbumByPaymentClientSecret = async (clientSecret) => {
    const album = await apiServerClient.album.getByClientSecret(clientSecret);

    const { albums, album: album2 } = await loadAlbumsAlbumFromUser(user, [
      album._id,
    ]);
    setAlbums(albums);
    setAlbumAndSaveToLocalStorage(user, album2);
    return album2;
  };

  const setAlbumAndSaveToLocalStorage = (user, album) => {
    if (!user) return null;

    if (album) {
      localStorageUtil.set(`${user._id}.albumId`, album._id);
    } else {
      localStorageUtil.remove(`${user._id}.albumId`);
    }

    setAlbum(album);
  };

  const loadAlbumId = (user) => {
    if (!user) return null;

    const albumId = localStorageUtil.get(`${user._id}.albumId`);

    return albumId;
  };

  const updateAlbums = (album) => {
    const albums2 = albums.filter((a) => a._id !== album?._id);
    albums2.push(album);
    setAlbums(albums2);
    setAlbum(album);
  };

  const createAlbum = async (name, cover, startDate, timeZone, packageId) => {
    const album = await apiServerClient.album.create(
      name,
      cover,
      startDate,
      timeZone,
      packageId
    );

    updateAlbums(album);

    return album;
  };

  const createAlbumFromGiftcard = async (
    name,
    cover,
    startDate,
    timeZone,
    code
  ) => {
    const album = await apiServerClient.album.createFromGiftcard(
      name,
      cover,
      startDate,
      timeZone,
      code
    );

    updateAlbums(album);

    return album;
  };

  const updateAlbumCover = async (cover) => {
    const album2 = await apiServerClient.album.updateCover(album._id, cover);
    updateAlbums(album2);
  };

  const updateAlbumName = async (name) => {
    const album2 = await apiServerClient.album.updateName(album._id, name);
    updateAlbums(album2);
    return album2;
  };

  const updateAlbumStartDate = async (startDate, timeZone) => {
    const album2 = await apiServerClient.album.updateStartDate(
      album._id,
      startDate,
      timeZone
    );
    updateAlbums(album2);
  };

  if (isLoadingAlbum) {
    return null;
  }

  const initialValue = {
    deviceId,
    apiServerClient,
    mediasOrderBy,
    setMediasOrderBy: setAndSaveMediasOrderBy,

    hideAddToHomeScreenButton,
    setHideAddToHomeScreenButton: setAndSaveHideAddToHomeScreenButton,

    user: {
      get: () => user,
      signupAsGuestAndJoin,
      registerGuest,
      signup,
      signinByEmailAndVerificationCode,
      signout,
      updateName: updateUserName,
      delete: deleteUser,
    },

    albums: {
      get: () => albums,
    },

    album: {
      get: () => album,
      set: (album) => setAlbumAndSaveToLocalStorage(user, album),
      markWelcomeMessageShown: markAlbumWelcomeMessageShown,
      welcomeMessageShown: albumWelcomeMessageShown,
      updateName: updateAlbumName,
      updateStartDate: updateAlbumStartDate,
      updateCover: updateAlbumCover,
      create: createAlbum,
      createFromGiftcard: createAlbumFromGiftcard,
      setByPaymentClientSecret: setAlbumByPaymentClientSecret,
      join: joinAlbum,
    },

    medias: {
      get: () => medias,
      isLoading: isLoadingMedias,
      delete: deleteMedia,
      refreshFromChanges: refreshMediasFromChanges,
    },
  };

  return (
    <appContext.Provider value={initialValue}>{children}</appContext.Provider>
  );
};
