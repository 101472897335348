import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import {
  faBarsStaggered,
  faCircleCheck,
  faRotate,
} from "@fortawesome/free-solid-svg-icons";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import placeholder from "../../../../assets/placeholder.jpg";
import mediaUtil from "../../../../utils/media.util";
import assetUtil from "../../../../utils/asset.util";
import colors from "../../../../utils/colors";
import cssUtil from "../../../../utils/css.util";
import albumUtil from "../../../../utils/album.util";

import SpinnerControl from "../../../controls/spinner.control";
import { useLanguage } from "../../../language.context";
import { useException } from "../../../exception.context";

import { useAppState } from "../appState.context";

import MenuModal from "./menu.model/modal";
import WelcomeAlbumOwnerModal from "./welcomealbumowner.modal/modal";
import MediaViewerModal from "./mediaviewer.modal/modal";
import JoinAlbumModal from "./joinalbum.modal/modal";
import PlusButton from "./plus.button/button";
import RecomendationsButton from "./recomendations.button/button";

const margin = 10;

const LogoControl = () => {
  return (
    <div
      style={{
        position: "fixed",
        left: 10,
        bottom: 10,
        textAlign: "center",
      }}
    >
      <img
        src="/assets/fulllogo5.png"
        style={{ height: 50, display: "inline" }}
      />
    </div>
  );
};

const AlbumPage = () => {
  const appStateCtx = useAppState();
  const languageCtx = useLanguage();
  const mediasRef = useRef();
  const exceptionCtx = useException();

  const timeoutHandle = useRef(0);
  const [size, setSize] = useState(200);
  const [rows, setRows] = useState([]);
  const [row, setRow] = useState();
  const [medias, setMedias] = useState([]);
  const [mediaIndex, setMediaIndex] = useState(-1);

  const [showMenuModal, setShowMenuModal] = useState(false);
  const [showMediaModal, setShowMediaModal] = useState(false);
  const loadedMediasRef = useRef(new Set());
  const loadingMediasRef = useRef({});

  const handleMediaClicked = (media) => {
    if (mediaUtil.hasBeenProcessed(media)) {
      const medias = rows
        .filter((r) => r.type === "medias")
        .flatMap((r) => r.medias)
        .filter((m) => mediaUtil.hasBeenProcessed(m));

      setMedias(medias);
      setMediaIndex(medias.indexOf(media));
      setShowMediaModal(true);
    } else {
      alert(
        languageCtx.getStringFromId("Please wait") +
          "\n" +
          languageCtx.getStringFromId(
            "Photo is being processed and will soon be ready"
          )
      );
    }
  };

  useEffect(() => {
    loadingMediasRef.current = {};
    loadedMediasRef.current = new Set();
  }, [
    appStateCtx.album.get()?._id,
    appStateCtx.mediasOrderBy,
    appStateCtx.user,
  ]);

  // on iOS we cannot cancel an image download being loaded via the img tag (it can be done on non webkit by setting the src to ""). So, we load the images via fetch instead. it is a bit slower.
  useEffect(() => {
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.00001, // iOS does not allow 0
    };

    const intersectionCallback = (entries) => {
      entries.forEach(async (entry) => {
        let elem = entry.target;
        const mediaId = elem.getAttribute("data-mediaid");

        if (entry.isIntersecting) {
          if (!loadedMediasRef.current.has(mediaId)) {
            if (!loadingMediasRef.current[!mediaId]) {
              const controller = new AbortController();
              loadingMediasRef.current[mediaId] = {
                controller,
              };

              try {
                const response = await fetch(elem.getAttribute("data-src"), {
                  signal: controller.signal,
                });

                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                elem.setAttribute("src", url);
                if (elem.getAttribute("data-hasthumb") === "1") {
                  loadedMediasRef.current.add(mediaId);
                }
                delete loadingMediasRef.current[mediaId];
              } catch (ex) {
                //console.error(ex);
              }
            }
          }
        } else {
          if (loadingMediasRef.current[mediaId]) {
            loadingMediasRef.current[mediaId].controller.abort();
          }
        }
      });
    };

    const observe = (observer) => {
      const imgs = document.querySelectorAll(".media img");

      for (var img of imgs) {
        observer.observe(img);
      }
    };

    const unobserve = (observer) => {
      const imgs = document.querySelectorAll(".media img");
      for (var img of imgs) {
        observer.unobserve(img);
      }
    };

    const observer = new IntersectionObserver(intersectionCallback, options);

    observe(observer);

    return () => {
      unobserve(observer);
      //observer.disconnect();
    };
  });

  useEffect(() => {
    const onResize = (event) => {
      if (!mediasRef.current) return;

      const maxImageWidth = 200;
      const maxImageWidthAndMargin = maxImageWidth + margin * 2;
      const windowWidth =
        mediasRef.current.offsetWidth -
        cssUtil.getScrollbarWidth() -
        margin * 2;
      const count = Math.max(
        Math.ceil(windowWidth / maxImageWidthAndMargin),
        3
      );
      const size = windowWidth / count;

      setSize(size - 2 * margin);
    };

    window.addEventListener("resize", onResize);
    onResize();

    return () => window.removeEventListener("resize", onResize);
  }, [appStateCtx.album.get()?._id]);

  useEffect(() => {
    if (!appStateCtx.medias) {
      setRows([]);
      return;
    }

    const refreshRows = () => {
      clearTimeout(timeoutHandle.current);
      timeoutHandle.current = 0;

      try {
        const rows = [];

        if (appStateCtx.mediasOrderBy === "date") {
          appStateCtx.medias
            .get()
            .sort(
              (a, b) => (b.asset.takenTime || 0) - (a.asset.takenTime || 0)
            );

          let headerRow = { id: "", type: "header", title: "" };
          let mediasRow = { id: "", type: "medias", medias: [] };

          for (var media of appStateCtx.medias.get()) {
            const id = media.asset.takenTime
              ? DateTime.fromMillis(media.asset.takenTime).toFormat(
                  languageCtx.getFormattingFromId("dateLong")
                )
              : languageCtx.getStringFromId("No date");

            if (id !== headerRow.id) {
              rows.push(
                (headerRow = {
                  id,
                  type: "header",
                  title: id,
                })
              );

              rows.push(
                (mediasRow = { id: id + "_medias", type: "medias", medias: [] })
              );
            }

            mediasRow.medias.push(media);
          }
        } else if (appStateCtx.mediasOrderBy === "name") {
          appStateCtx.medias.get().sort((a, b) => {
            let result = a.ownerUser.name.localeCompare(b.ownerUser.name);

            if (result !== 0) return result;

            result = a.ownerUserId
              .toString()
              .localeCompare(b.ownerUserId.toString());

            if (result !== 0) return result;

            return (b.asset.takenTime || 0) - (a.asset.takenTime || 0);
          });

          let headerRow = { id: "", type: "header", title: "" };
          let mediasRow = { id: "", type: "medias", medias: [] };

          for (var media of appStateCtx.medias.get()) {
            const id = media.ownerUser._id.toString();

            if (id !== headerRow.id) {
              rows.push(
                (headerRow = {
                  id,
                  type: "header",
                  title: media.ownerUser.name,
                })
              );
              rows.push(
                (mediasRow = { id: id + "_medias", type: "medias", medias: [] })
              );
            }

            mediasRow.medias.push(media);
          }
        }

        setRows(rows);
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }

      const getMediaChanges = () => {
        try {
          appStateCtx.medias.refreshFromChanges();
        } catch (ex) {
          console.log(ex);
        }
      };

      if (
        appStateCtx.medias.get().some((m) => !mediaUtil.hasBeenProcessed(m))
      ) {
        timeoutHandle.current = setTimeout(getMediaChanges, 1000 * 30);
      }
      // added 2024-06-21
      else {
        timeoutHandle.current = setTimeout(getMediaChanges, 1000 * 60 * 5);
      }
    };

    refreshRows();

    return () => {
      clearTimeout(timeoutHandle.current);
      timeoutHandle.current = 0;
    };
  }, [appStateCtx.medias, appStateCtx.mediasOrderBy]);

  const handleHeaderRowPressed = async (row) => {
    if (
      // must be owner
      appStateCtx.user.get()._id === appStateCtx.album.get().ownerUserId &&
      // cannot block owner
      row.id !== appStateCtx.user.get()._id &&
      // must be sorted by name
      appStateCtx.mediasOrderBy === "name"
    ) {
      setRow(row);
    }
  };

  const renderHeaderRow = (row) => {
    const rowKey = row.id.toString();
    return (
      <div className="mt-3" key={rowKey}>
        <div className="text-center">
          <h3 onClick={() => handleHeaderRowPressed(row)}>{row.title}</h3>
        </div>
      </div>
    );
  };

  const renderMediasRow = (row) => {
    return (
      <div style={{ margin }} key={row.id} className="mediaRow">
        {row.medias.map((media) => {
          return (
            <div
              onClick={() => handleMediaClicked(media)}
              className="d-inline-block clickable position-relative media"
              style={{
                margin,
              }}
              key={media._id}
            >
              <img
                style={{
                  height: size,
                  width: size,
                  objectFit: "cover",
                }}
                className="thumb"
                data-src={media.thumbFile ? media.thumbFile.uri : placeholder}
                data-hasthumb={media.thumbFile ? "1" : "0"}
                data-mediaid={media._id}
              />
              {mediaUtil.isVideo(media) && (
                <div className="position-absolute left-5 bottom-0 font-white fw-bold">
                  {assetUtil.durationToString(media.asset.duration)}
                </div>
              )}
              {mediaUtil.isDownloaded(
                media,
                appStateCtx.deviceId,
                // TODO
                [], //mediaLibraryCtx.assets,
                []
              ) && (
                <div className="position-absolute right-5 bottom-0">
                  <FontAwesomeIcon icon={faCircleCheck} />
                </div>
              )}
              {!mediaUtil.hasBeenProcessed(media) && (
                <div className="position-absolute right-5 top-0 font-white">
                  <FontAwesomeIcon icon={faRotate} />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderRow = (row) => {
    if (row.type === "header") {
      return renderHeaderRow(row);
    } else {
      return renderMediasRow(row);
    }
  };

  const handleOptionsClicked = () => {
    setShowMenuModal(true);
  };

  if (!appStateCtx.album.get()) {
    return <JoinAlbumModal />;
  }

  const states = [
    {
      name: "Not started",
      matches: (album) => !album.status.started,
      render: (album) => {
        const now = DateTime.now();
        const diff = DateTime.fromISO(album.startDate).diff(now, [
          "days",
          "hours",
        ]);

        return (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h4>{languageCtx.getStringFromId("The album will begin in")}</h4>
            <div>
              <h2>{albumUtil.getDiffAsString(languageCtx, diff)}</h2>
            </div>
          </div>
        );
      },
    },
  ];

  const state = states.find((s) => s.matches(appStateCtx.album.get()));

  return (
    <>
      <Navbar
        fixed="top"
        expand="lg"
        className="menu bg-white"
        style={{ backgroundColor: colors.white }}
      >
        <Navbar.Brand className="ms-3">
          <div style={{ fontSize: 9, color: colors.gray }}>
            {languageCtx.getStringFromId("ALBUM")}
          </div>
          <div className="fs-3">{appStateCtx.album.get().name}</div>
        </Navbar.Brand>
        <Nav className="me-auto"></Nav>
        <Nav style={{ flexDirection: "row" }}>
          <RecomendationsButton />
          <button
            className="btn btn-blue ms-1 me-3"
            onClick={handleOptionsClicked}
          >
            <FontAwesomeIcon icon={faBarsStaggered} />
          </button>
        </Nav>
      </Navbar>
      <div
        ref={mediasRef}
        style={{
          flex: 1,
          marginTop: 75,
        }}
      >
        {appStateCtx.medias.isLoading && (
          <div className="text-center mt-3">
            <SpinnerControl />
          </div>
        )}
        {rows.map(renderRow)}
        {state?.render(appStateCtx.album.get(), languageCtx)}
      </div>
      <MenuModal show={showMenuModal} setShow={setShowMenuModal} />
      <JoinAlbumModal />
      <WelcomeAlbumOwnerModal />
      <MediaViewerModal
        show={showMediaModal}
        setShow={setShowMediaModal}
        medias={medias}
        initialIndex={mediaIndex}
      />
      <LogoControl />

      <PlusButton />
    </>
  );
};

export default AlbumPage;
