import { useState } from "react";
import { DateTime } from "luxon";

import colors from "../../../../../../utils/colors";

import { useLanguage } from "../../../../../language.context";
import { useException } from "../../../../../exception.context";
import { useMessage } from "../../../../../message.context";

import { useAppState } from "../../../appState.context";

import SelectDateFromCalenderControl from "../createalbum/selectDateFromCalender.control";

export default ({ ctx }) => {
  const languageCtx = useLanguage();
  const appStateCtx = useAppState();
  const exceptionCtx = useException();
  const messageCtx = useMessage();

  const [isWorking, setIsWorking] = useState(false);

  const handleSavePressed = async () => {
    try {
      setIsWorking(true);

      await appStateCtx.album.updateStartDate(
        DateTime.fromISO(ctx.updateStartDate.startDate)
          .set({ hour: 0 })
          .toISODate(),
        DateTime.now().zoneName
      );

      messageCtx.showSuccess(languageCtx.getStringFromId("Begin date changed"));
      ctx.popItem();
    } catch (ex) {
      exceptionCtx.handleException(ex);
    } finally {
      setIsWorking(false);
    }
  };

  const isFormOk = () => {
    if (!ctx.updateStartDate.startDate) return false;

    return true;
  };

  const handleStartDateClicked = () => {
    ctx.pushItem(languageCtx.getIdFromId("Select date"), (ctx) => (
      <SelectDateFromCalenderControl
        ctx={ctx}
        date={ctx.updateStartDate.startDate}
        setDate={ctx.updateStartDate.setStartDate}
      />
    ));
    //setShowSelectDateModal(true);
  };

  return (
    <div className="text-center p-4">
      <div className="fs-2 mb-3">
        {languageCtx.getStringFromId("Select new start date")}
      </div>
      <div id="calender">
        <input
          type="text"
          className="form-control text-center form-control-lg clickable"
          value={DateTime.fromISO(ctx.updateStartDate.startDate).toFormat(
            languageCtx.getFormattingFromId("dateLong")
          )}
          onClick={handleStartDateClicked}
          readOnly
        />
      </div>
      <div className="mt-3">
        <div
          style={{
            color: colors.red,
          }}
        >
          {ctx.updateStartDate.startDate <= DateTime.now().toISODate()
            ? languageCtx.getStringFromId(
                "Your album will last 3 weeks and start immediatly"
              )
            : languageCtx.getStringFromId("Your album will last 3 weeks")}
        </div>
      </div>
      <div className="mt-3">
        <button
          className="btn btn-blue"
          onClick={handleSavePressed}
          disabled={!isFormOk()}
        >
          {languageCtx.getStringFromId("Save")}
        </button>
      </div>
      {/* <SelectdateModal
        show={showSelectDateModal}
        setShow={setShowSelectDateModal}
        date={startDate}
        setDate={setStartDate}
      /> */}
    </div>
  );
};
