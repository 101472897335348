import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAppState } from "../appState.context";

import WelcomeModal from "./welcome2.modal/modal";

const NoAlbumPage = () => {
  const navigate = useNavigate();

  const appStateCtx = useAppState();

  const [showWelcomeModal, setShowWelcomeModal] = useState(true);

  useEffect(() => {
    if (appStateCtx.album.get()) {
      navigate(`/app/albums/${appStateCtx.album.get()._id}`);
    }
  }, []);

  return <WelcomeModal show={showWelcomeModal} setShow={setShowWelcomeModal} />;
};

export default NoAlbumPage;
