import { useEffect } from "react";
import { Routes, Route, useNavigate, Navigate, Outlet } from "react-router-dom";

import { useAppState, AppStateContextProvider } from "./app/appState.context";
import {
  useAffiliateAppState,
  AffiliateAppStateContextProvider,
} from "./affiliate/appState.context";
import MainPage from "./main.page";
import InviteCardsScreen from "./invitecards.page";
import PrivacyPolicyScreen from "./privacypolicy.page";
import TermsOfServiceScreen from "./termsofservice.page";

import PaymentPage from "./app/payment2.page";
import { AuthContextProvider } from "./app/auth/context";
import EnterNamePage from "./app/auth/entername.control";
import EnterEmailPage from "./app/auth/enteremail.control";
import EnterVerificationCodePage from "./app/auth/enterverificationcode.control";
import NoAlbumsPage from "./app/noalbums/noalbums.page";
import AlbumPage from "./app/albums/album.page";
import JoinPage from "./app/albums/join.page";

import AffiliateSigninPage from "./affiliate/signin.page";
import AffiliateGiftcardsPage from "./affiliate/giftcards.page";
import AffiliateGiftcardPage from "./affiliate/giftcard.page";
import AffiliatePaymentPage from "./affiliate/payment.page";
import AffiliateChargesPage from "./affiliate/charges.page";
import AffiliateWelcomePage from "./affiliate/welcome.page";
import AffiliateInvoicesPage from "./affiliate/invoices.page";
import AffiliateInvoicePage from "./affiliate/invoice.page";

const RedirectToApp = () => {
  const navigate = useNavigate();
  const appStateCtx = useAppState();

  useEffect(() => {
    if (appStateCtx.user.get()) {
      if (appStateCtx.album.get()) {
        navigate(`/app/albums/${appStateCtx.album.get()._id}`);
      } else {
        navigate(`/app/albums`);
      }
    } else {
      navigate("/app/auth/enteremail");
    }
  });

  return null;
};

const RedirectToAffiliate = () => {
  const navigate = useNavigate();
  const appStateCtx = useAffiliateAppState();

  useEffect(() => {
    if (appStateCtx.affiliate.get()) {
      navigate(`/affiliate/welcome`);
    } else {
      navigate("/affiliate/auth");
    }
  });

  return null;
};

const AppContainer = () => {
  return (
    <AppStateContextProvider>
      <Outlet />
    </AppStateContextProvider>
  );
};

const AffiliateContainer = () => {
  return (
    <AffiliateAppStateContextProvider>
      <Outlet />
    </AffiliateAppStateContextProvider>
  );
};

export default () => {
  return (
    <>
      <Routes>
        <Route path="/">
          <Route index element={<MainPage />} />
          <Route path="termsofservice" element={<TermsOfServiceScreen />} />
          <Route path="privacypolicy" element={<PrivacyPolicyScreen />} />
          <Route path="invitecards/:albumId" element={<InviteCardsScreen />} />
          <Route path="app" element={<AppContainer />}>
            <Route index element={<RedirectToApp />} />
            <Route path="payment" element={<PaymentPage />} />
            <Route path="auth" element={<AuthContextProvider />}>
              <Route path="entername" element={<EnterNamePage />} />
              <Route path="enteremail" element={<EnterEmailPage />} />
              <Route
                path="enterverificationcode"
                element={<EnterVerificationCodePage />}
              />
            </Route>
            <Route path="albums">
              <Route index element={<NoAlbumsPage />} />
              <Route path=":albumId">
                <Route index element={<AlbumPage />} />
                <Route path="join" element={<JoinPage />} />
              </Route>
            </Route>
          </Route>
          <Route path="affiliate" element={<AffiliateContainer />}>
            <Route index element={<RedirectToAffiliate />} />
            <Route path="auth" element={<AffiliateSigninPage />} />
            <Route path="welcome" element={<AffiliateWelcomePage />} />
            <Route path="giftcards">
              <Route index element={<AffiliateGiftcardsPage />} />
              <Route path="payment" element={<AffiliatePaymentPage />} />
              <Route path="invoices">
                <Route index element={<AffiliateInvoicesPage />} />
                <Route path=":invoiceId" element={<AffiliateInvoicePage />} />
              </Route>
              <Route path="charges" element={<AffiliateChargesPage />} />
              <Route path="new" element={<AffiliateGiftcardPage />} />
              <Route path=":giftcardId" element={<AffiliateGiftcardPage />} />
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};
