import { useLanguage } from "../../../../../language.context";

import { useAppState } from "../../../appState.context";

export default ({ ctx }) => {
  const languageCtx = useLanguage();
  const appStateCtx = useAppState();

  return (
    <div className="text-center p-4">
      <div className="fs-2">{languageCtx.getStringFromId("Info")}</div>

      <div>
        <label className="form-label fw-bold">
          {languageCtx.getStringFromId("name")}
        </label>
        <div>{appStateCtx.album.get().name}</div>
      </div>
      <div className="mt-3">
        <label className="form-label fw-bold">
          {languageCtx.getStringFromId("Package")}
        </label>
        <div>
          {
            languageCtx.getPackageFromId(
              appStateCtx.album.get().package.productId
            ).name
          }
        </div>
      </div>

      <div className="mt-3">
        <label className="form-label fw-bold">
          {languageCtx.getStringFromId("Invites")}
        </label>
        <div>{`${appStateCtx.album.get().users.length} / ${
          appStateCtx.album.get().package.inviteCount
        }`}</div>
      </div>
      <div className="mt-3">
        <label className="form-label fw-bold">
          {languageCtx.getStringFromId("Photos/videos")}
        </label>
        <div>
          {`${
            appStateCtx.album.get().usedPhotoCount +
            appStateCtx.album.get().usedVideoCount
          } / ${appStateCtx.album.get().package.mediaCount}`}
        </div>
      </div>
    </div>
  );
};
