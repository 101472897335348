import { useEffect, useState } from "react";

import albumUtil from "../../../../../../../utils/album.util";

import { useLanguage } from "../../../../../../language.context";

import { useAppState } from "../../../../appState.context";

import ConfirmControl from "./confirm.control";

export default ({ ctx, userId }) => {
  const appStateCtx = useAppState();
  const languageCtx = useLanguage();

  const [user, setUser] = useState();

  const refresh = async () => {
    const user = await appStateCtx.apiServerClient.album.getUser(
      appStateCtx.album.get()._id,
      userId
    );

    setUser(user);
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleUnblockUserPressed = async () => {
    ctx.pushItem(languageCtx.getIdFromId("Unblock"), (ctx) => (
      <ConfirmControl ctx={ctx} block={false} user={user} />
    ));
  };

  const handleBlockUserAndDeleteAllPhotosPressed = async () => {
    ctx.pushItem(languageCtx.getIdFromId("Block"), (ctx) => (
      <ConfirmControl ctx={ctx} block={true} user={user} />
    ));
  };

  if (!user) return null;

  return (
    <div className="p-4">
      {user && (
        <div style={{ textAlign: "center" }}>
          <div style={{ fontSize: 20, fontWeight: "bold" }}>{user.name}</div>
          {user.email && <div style={{ fontSize: 14 }}>{user.email}</div>}

          <div style={{ fontSize: 14 }}>
            {albumUtil.isUserOwner(appStateCtx.album.get(), user)
              ? languageCtx.getStringFromId("Owner")
              : languageCtx.getStringFromId("Invite")}
          </div>

          {!albumUtil.isUserOwner(appStateCtx.album.get(), user) && (
            <div className="mt-3">
              {user.blocked && (
                <button
                  className="btn btn-blue"
                  onClick={handleUnblockUserPressed}
                >
                  {languageCtx.getStringFromId("unblockUser")}
                </button>
              )}

              {!user.blocked && (
                <button
                  className="btn btn-blue"
                  onClick={handleBlockUserAndDeleteAllPhotosPressed}
                >
                  {languageCtx.getStringFromId("blockUserAndDeleteAllPhotos")}
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
