import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import { useException } from "../../exception.context";
import { useLanguage } from "../../language.context";
import TableControl from "../../controls/table.control";
import Modal from "../../controls/modal";
import SelectInput from "../../controls/select.input";

import { useAffiliateAppState } from "./appState.context";

export default () => {
  const navigate = useNavigate();

  const languageCtx = useLanguage();
  const exceptionCtx = useException();
  const appStateCtx = useAffiliateAppState();

  const options = [
    languageCtx.getStringFromId("Unused giftcards"),
    languageCtx.getStringFromId("All giftcards"),
  ];

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [allGiftcards, setAllGiftcards] = useState([]);
  const [giftcards, setGiftcards] = useState([]);
  const [option, setOption] = useState(options[0]);

  useEffect(() => {
    const refresh = async () => {
      try {
        const giftcards =
          await appStateCtx.apiServerClient.affiliate.getGiftcards();

        giftcards.sort((a, b) => -a.createdAt.localeCompare(b.createdAt));

        setAllGiftcards(giftcards);
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };
    refresh();
  }, []);

  useEffect(() => {
    if (option === options[0]) {
      setGiftcards(allGiftcards.filter((t) => !t.usedAt));
    } else {
      setGiftcards(allGiftcards);
    }
  }, [option, allGiftcards]);

  const handleNewClicked = () => {
    navigate("./new");
  };

  const handleGiftcardClicked = (giftcard) => {
    navigate(`./${giftcard._id}`);
  };

  const handleFilterClicked = () => {
    setShowFilterModal(true);
  };

  return (
    <div className="page">
      <div className="container">
        <div className="mt-4">
          <h3>{languageCtx.getStringFromId("Giftcards")}</h3>
        </div>
        <div>
          {languageCtx.getStringFromId(
            "Below you can find a list of all giftcards ever created."
          )}
        </div>

        <div className="d-flex justify-content-between mt-4">
          <button className="btn btn-blue" onClick={handleNewClicked}>
            {languageCtx.getStringFromId("New2")}
          </button>
          <button className="btn btn-blue" onClick={handleFilterClicked}>
            <FontAwesomeIcon icon={faFilter} />
          </button>
        </div>

        <TableControl
          columns={[
            {
              header: languageCtx.getStringFromId("Code"),
              valueExtractor: (c) => c.code,
            },
            {
              header: languageCtx.getStringFromId("Created"),
              valueExtractor: (c) => DateTime.fromISO(c.createdAt).toISODate(),
            },
            {
              header: languageCtx.getStringFromId("Package"),
              valueExtractor: (c) => c.package?.name,
            },
            {
              header: languageCtx.getStringFromId("Reference"),
              valueExtractor: (c) => c.reference,
            },
            {
              header: languageCtx.getStringFromId("Used"),
              valueExtractor: (c) => c.usedAt,
            },
          ]}
          rows={giftcards}
          keyExtractor={(c) => c._id}
          onRowClicked={handleGiftcardClicked}
        />
      </div>
      <Modal
        show={showFilterModal}
        setShow={setShowFilterModal}
        title={languageCtx.getStringFromId("Filter")}
      >
        <SelectInput
          items={options}
          nameExtractor={(i) => i}
          valueExtractor={(i) => i}
          value={option}
          onChange={setOption}
        />
      </Modal>
    </div>
  );
};
