const util = {
  email: {
    isValid: (email) => {
      var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      return re.test(email);
    },
  },
  string: {
    isValid: (str, trim, minLength, maxLength) => {
      if (!str) return false;
      if (trim) {
        str = str.trim();
      }

      if (minLength !== undefined) {
        if (str.length < minLength) return false;
      }

      if (maxLength !== undefined) {
        if (str.length < maxLength) return false;
      }

      return true;
    },
    isPhone: (str) => {
      return str.trim().length === 8;
    },
  },
  url: {
    isValid: (url) => {
      const re =
        /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;

      return re.test(url);
    },
  },
  date: {
    isJSDate: (date, minDate) => {
      if (!date) return false;
      if (typeof date.getMonth !== "function") return false;
      if (minDate) {
        if (date < minDate) return false;
      }

      return true;
    },
    isValidISODate: (date, minDate) => {
      if (!util.string.isString(date)) return false;
      if (!/^\d{4}-\d{2}-\d{2}$/.test(date)) return false;

      if (minDate) {
        if (date < minDate) return false;
      }

      return true;
    },
    isValidISO: (date) => {
      if (!util.string.isString(date)) return false;
      if (
        !/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}(-\d{2}:\d{2}|Z)$/.test(
          date
        )
      )
        return false;

      return true;
    },
  },
  number: {
    isNumber: (val) => {
      return true;
    },
  },
  float: {
    isValid: (num, minValue, maxValue) => {
      if (isNaN(num)) return false;
      var num2 = parseFloat(num);
      if (minValue !== undefined) {
        if (num2 < minValue) return false;
      }

      if (maxValue !== undefined) {
        if (num2 > maxValue) return false;
      }

      return true;
    },
  },
  int: {
    isValid: (num, minValue, maxValue) => {
      if (isNaN(num)) return false;
      var num2 = parseInt(num);
      if (minValue !== undefined) {
        if (num2 < minValue) return false;
      }

      if (maxValue !== undefined) {
        if (num2 > maxValue) return false;
      }

      return true;
    },
  },
  phone: {
    isValid: (phone) => {
      return !!phone && util.string.isValid(phone.toString(), true, 10, 10);
      //return util.int.isValid(phone, 10, 10);
    },
  },
};

export default util;
