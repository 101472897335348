import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faClock } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";

import albumUtil from "../../../../../../utils/album.util";
import stringUtil from "../../../../../../utils/string.util";
import colors from "../../../../../../utils/colors";

import { useLanguage } from "../../../../../language.context";
import { useException } from "../../../../../exception.context";

import { useAppState } from "../../../appState.context";

import PayControl from "./pay.control";

export default ({ ctx }) => {
  const navigate = useNavigate();

  const appStateCtx = useAppState();
  const languageCtx = useLanguage();
  const exceptionCtx = useException();

  const handleCreateClicked = async () => {
    try {
      ctx.setIsWorking(true);

      const album = await appStateCtx.album.create(
        ctx.createAlbum.name,
        ctx.createAlbum.cover._id || ctx.createAlbum.cover, // cover.id or file
        ctx.createAlbum.startDate,
        ctx.createAlbum.timeZone,
        ctx.createAlbum.package._id
      );

      ctx.setShow(false);
      navigate(`/app/albums/${album._id}`, { replace: true });
    } catch (ex) {
      exceptionCtx.handleException(ex);
    } finally {
      ctx.setIsWorking(false);
    }
  };

  const handleGotoPaymentClicked = () => {
    ctx.pushItem(languageCtx.getIdFromId("pay"), (ctx) => (
      <PayControl ctx={ctx} />
    ));
  };

  const handleCreateWithGiftcardClicked = async () => {
    try {
      const album = await appStateCtx.album.createFromGiftcard(
        ctx.createAlbum.name,
        ctx.createAlbum.cover._id || ctx.createAlbum.cover, // cover.id or file
        ctx.createAlbum.startDate,
        ctx.createAlbum.timeZone,
        ctx.createAlbum.giftcardCode
      );
      ctx.setShow(false);
      navigate(`/app/albums/${album._id}`, { replace: true });
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  return (
    <div className="text-center p-4">
      <div className="fs-2 lh-sm">
        {languageCtx.getStringFromId("Album details")}
      </div>

      <div className="mt-2">
        <div className="fw-bold">{languageCtx.getStringFromId("name")}</div>
        <div>{ctx.createAlbum.name}</div>

        <div className="cover mt-2">
          <img
            src={ctx.createAlbum.cover.uri}
            style={{
              width: "100%",
              height: "100%",
              objectFit:
                ctx.createAlbum.cover?.source === "db" ? "contain" : "cover",
            }}
          />
        </div>
        <div className="mt-2">
          <div className="fw-bold">
            {languageCtx.getStringFromId("Invites")}
          </div>
          <div>{ctx.createAlbum.package.inviteCount}</div>
        </div>
        <div className="mt-2">
          <div className="fw-bold">
            {languageCtx.getStringFromId("startDate")}
          </div>
          <div>
            {ctx.createAlbum.startDate <= DateTime.now().toISO() && (
              <>{languageCtx.getStringFromId("Now")}</>
            )}
            {ctx.createAlbum.startDate > DateTime.now().toISO() && (
              <>
                <FontAwesomeIcon icon={faCalendar} color={colors.darkblue} />{" "}
                {DateTime.fromISO(ctx.createAlbum.startDate).toFormat(
                  languageCtx.getFormattingFromId("dateLong")
                )}
                {"  "}
                <FontAwesomeIcon icon={faClock} color={colors.darkblue} />{" "}
                {DateTime.fromISO(ctx.createAlbum.startDate).toFormat(
                  languageCtx.getFormattingFromId("time")
                )}
              </>
            )}
          </div>
        </div>
        <div className="mt-2">
          <div className="fw-bold">
            {languageCtx.getStringFromId("duration")}
          </div>
          <div>
            {stringUtil.formatString(
              languageCtx.getStringFromId("X days"),
              ctx.createAlbum.package.dayCount
            )}
          </div>
        </div>

        <div className="mt-2">
          <div className="fw-bold">
            {languageCtx.getStringFromId("Photos/videos")}
          </div>
          <div>{ctx.createAlbum.package.mediaCount}</div>
        </div>
      </div>

      {ctx.createAlbum.giftcardCode && (
        <div className="mt-3">
          <button
            className="btn btn-blue"
            onClick={handleCreateWithGiftcardClicked}
            disabled={ctx.isWorking}
          >
            {languageCtx.getStringFromId("Create using gift card")}
          </button>
        </div>
      )}

      {!ctx.createAlbum.giftcardCode &&
        ctx.createAlbum.package.priceAmountMicros === 0 && (
          <div className="mt-3">
            <button
              className="btn btn-blue"
              onClick={handleCreateClicked}
              disabled={ctx.isWorking}
            >
              {`${languageCtx.getStringFromId(
                "create"
              )} - ${languageCtx.getStringFromId("free")}`}
            </button>
          </div>
        )}

      {!ctx.createAlbum.giftcardCode &&
        ctx.createAlbum.package.priceAmountMicros > 0 && (
          <>
            <div className="mt-3">
              <button
                className="btn btn-blue"
                onClick={handleGotoPaymentClicked}
              >
                {`${languageCtx.getStringFromId("pay")} - ${albumUtil.price
                  .fromMillis(ctx.createAlbum.package.priceAmountMicros)
                  .toFixed(2)} ${ctx.createAlbum.package.currencyCode}`}
              </button>
            </div>
          </>
        )}
    </div>
  );
};
