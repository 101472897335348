import { useLanguage } from "../../../../../../language.context";

import { useAppState } from "../../../../appState.context";

export default ({ ctx }) => {
  const languageCtx = useLanguage();
  const appStateCtx = useAppState();

  return (
    <div className="text-center p-4">
      <div className="fs-2">{languageCtx.getStringFromId("Info")}</div>

      <div>
        <label className="form-label fw-bold">
          {languageCtx.getStringFromId("name")}
        </label>
        <div>{appStateCtx.user.get().name}</div>
      </div>
      <div className="mt-3">
        <label className="form-label fw-bold">
          {languageCtx.getStringFromId("Email")}
        </label>
        <div>{appStateCtx.user.get().email}</div>
      </div>
    </div>
  );
};
