import { useRef } from "react";

import CodeControl from "../../../../../controls/code.control2";
import { useLanguage } from "../../../../../language.context";
import { useException } from "../../../../../exception.context";

import { useAppState } from "../../../appState.context";

import ConfirmControl from "./confirm.control";

export default ({ ctx }) => {
  const appStateCtx = useAppState();
  const languageCtx = useLanguage();
  const exceptionCtx = useException();

  const btnNextRef = useRef();

  const setCodeAdapter = (code) => {
    const code2 = code.replace(/0-9A-Z/g, "").toUpperCase();
    ctx.createAlbum.setGiftcardCode(code2);
  };

  const isFormOk = () => {
    if (ctx.createAlbum.giftcardCode.length !== 6) return false;

    return true;
  };

  const handlePaywithGiftcardPressed = async () => {
    try {
      ctx.setIsWorking(true);

      const pkg = await appStateCtx.apiServerClient.giftcard.getPackage(
        ctx.createAlbum.giftcardCode
      );

      ctx.createAlbum.setPackage(pkg);
      ctx.pushItem(languageCtx.getIdFromId("Create album"), (ctx) => (
        <ConfirmControl ctx={ctx} />
      ));
    } catch (ex) {
      exceptionCtx.handleException(ex);
    } finally {
      ctx.setIsWorking(false);
    }
  };

  return (
    <div className="text-center p-4">
      <div>
        <div className="fs-2 lh-sm">
          {languageCtx.getStringFromId("Enter the gift card code")}
        </div>
        <div className="mt-3">
          <CodeControl
            onSubmit={handlePaywithGiftcardPressed}
            value={ctx.createAlbum.giftcardCode}
            onChange={setCodeAdapter}
            forceUppercase={true}
            fields={6}
            autoFocus={true}
            inputStyle={{
              padding: 0,
              marginLeft: 2,
              marginRight: 2,
              maxWidth: 45,
              maxHeight: 55,
              textAlign: "center",
              fontSize: 32,
              fontWeight: 700,
            }}
          />
        </div>
      </div>

      <div className="mt-3">
        <button
          ref={btnNextRef}
          onClick={handlePaywithGiftcardPressed}
          disabled={!isFormOk()}
          className="btn btn-blue"
        >
          {languageCtx.getStringFromId("continue")}
        </button>
      </div>
    </div>
  );
};
