import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import stringUtil from "../../../utils/string.util";
import albumUtil from "../../../utils/album.util";
import settings from "../../../utils/settings";

import { useException } from "../../exception.context";
import { useLanguage } from "../../language.context";

import { useAffiliateAppState } from "./appState.context";

export default () => {
  const languageCtx = useLanguage();
  const exceptionCtx = useException();
  const appStateCtx = useAffiliateAppState();

  const [productPackages, setProductPackages] = useState([]);
  const [giftcards, setGiftcards] = useState([]);
  const [runningBalance, setRunningBalance] = useState(0);
  const [dueBalance, setDueBalance] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const refresh = async () => {
      try {
        setIsLoading(true);
        let giftcards =
          await appStateCtx.apiServerClient.affiliate.getGiftcards();
        giftcards = giftcards.filter((c) => !c.usedAt);
        setGiftcards(giftcards);

        const runningBalance =
          await appStateCtx.apiServerClient.affiliate.getRunningBalance();
        const dueBalance =
          await appStateCtx.apiServerClient.affiliate.getDueBalance();

        setRunningBalance(runningBalance);
        setDueBalance(dueBalance);

        let affiliatePackages =
          await appStateCtx.apiServerClient.affiliate.getPackages("affiliate");
        let publicPackages =
          await appStateCtx.apiServerClient.affiliate.getPackages("public");

        affiliatePackages.sort(
          (a, b) => a.priceAmountMicros - b.priceAmountMicros
        );

        const productPackages = affiliatePackages.map((p) => {
          const publicPackage = publicPackages.find(
            (p2) => p2.productId === p.productId
          );

          return { affiliate: p, public: publicPackage };
        });
        setProductPackages(productPackages);
      } catch (ex) {
        exceptionCtx.handleException(ex);
      } finally {
        setIsLoading(false);
      }
    };
    refresh();
  }, []);

  const props = [
    {
      name: languageCtx.getStringFromId("Invites"),
      handler: (pp) => pp.affiliate.inviteCount,
    },
    {
      name: languageCtx.getStringFromId("Photos/videos"),
      handler: (pp) => pp.affiliate.mediaCount,
    },
    {
      name: languageCtx.getStringFromId("Max minutes per video"),
      handler: (pp) => pp.affiliate.maxSecondsPerVideo / 60,
    },
    {
      name: languageCtx.getStringFromId("duration"),
      handler: (pp) =>
        stringUtil.formatString(
          languageCtx.getStringFromId("X days"),
          pp.affiliate.dayCount
        ),
    },
    {
      name: languageCtx.getStringFromId("Our price"),
      strikethrough: true,
      handler: (pp) => {
        return pp.public.priceAmountMicros > 0
          ? `${albumUtil.price
              .fromMillis(pp.public.priceAmountMicros)
              .toFixed(2)} ${pp.public.currencyCode}`
          : "-";
      },
    },
    {
      name: languageCtx.getStringFromId("Your price"),
      bold: true,
      handler: (pp) => {
        return pp.affiliate.priceAmountMicros > 0
          ? `${albumUtil.price
              .fromMillis(pp.affiliate.priceAmountMicros)
              .toFixed(2)} ${pp.affiliate.currencyCode}`
          : "-";
      },
    },
  ];

  if (isLoading) return null;

  return (
    <div className="page">
      <div className="container">
        <div className="mt-4">
          <h3>
            {languageCtx.getStringFromId("Welcome")}{" "}
            {appStateCtx.affiliate.get().fullname}
          </h3>
        </div>
        <div className="mt-4">
          <p>
            {languageCtx.getStringFromId(
              "Our affiliate program is dedicated to resellers who would like a competitive advantage by offering Fotisima as part of their service. This gives our partners a chance to earn some extra, even offering additional services like designing and printing QR Codes, setting up the album and offering photo printing services."
            )}
          </p>
          <p>
            {languageCtx.getStringFromId(
              "Use this platform to buy Fotisima gift cards at a discounted price and offer Fotisima to your clients under your own conditions and pricing."
            )}
          </p>
          <p>
            {languageCtx.getStringFromId(
              "Please feel free to contact us if you have any doubts or questions:"
            )}{" "}
            <a href={`mailto:${settings.company.emails.helpdesk}`}>
              {settings.company.emails.helpdesk}
            </a>
          </p>
        </div>
        <div className="row g-3 mt-4">
          <h4>{languageCtx.getStringFromId("Your current status")}</h4>
          <div className="col-md-4 col-sm-12">
            <div className="p-4 rounded border d-flex align-items-center justify-content-center text-center flex-column bg-light">
              <div className="text-black">
                <h5>{languageCtx.getStringFromId("Unused giftcards")}</h5>
              </div>

              <Link to="../giftcards" className="text-black mt-2">
                {giftcards.length}
              </Link>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="p-4 rounded border d-flex align-items-center justify-content-center text-center flex-column bg-light">
              <div className="fw-bold">
                <h5>{languageCtx.getStringFromId("Current month balance")}</h5>
              </div>

              <Link to="../giftcards/charges" className="text-black mt-2">
                {(runningBalance / 1000000).toFixed(2)}{" "}
                {appStateCtx.affiliate.get().currencyCode}
              </Link>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="p-4 rounded border d-flex align-items-center justify-content-center text-center flex-column bg-light">
              <div className="text-black">
                <h5>{languageCtx.getStringFromId("Invoices balance")}</h5>
              </div>
              <Link to="../giftcards/invoices" className="text-black mt-2">
                {(dueBalance / 1000000).toFixed(2)}{" "}
                {appStateCtx.affiliate.get().currencyCode}
              </Link>
            </div>
          </div>
        </div>
        <div className="mt-4 p-2">
          <h4>Packages</h4>
          <div className="table-responsive">
            <table className="table table-striped border-light">
              <thead>
                <tr>
                  <th></th>
                  {productPackages.map((pp) => (
                    <th className="text-center" key={pp.affiliate.productId}>
                      {
                        languageCtx.getPackageFromId(pp.affiliate.productId)
                          .name
                      }
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {props.map((row) => {
                  return (
                    <tr key={row.name}>
                      <th>{row.name}</th>
                      {productPackages.map((pp) => {
                        return (
                          <td
                            className={`text-center ${
                              row.bold ? "fw-bold" : ""
                            } ${
                              row.strikethrough
                                ? "text-decoration-line-through"
                                : ""
                            }
                            } `}
                            key={pp._id}
                          >
                            {row.handler(pp)}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
