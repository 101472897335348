import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";

import errorUtil from "../../../../../utils/error.util";

import { useLanguage } from "../../../../language.context";
import { useException } from "../../../../exception.context";
import Modal from "../../../../controls/modal";

import { useAppState } from "../../appState.context";

import JoinControl from "./join.control";

export default () => {
  const navigate = useNavigate();
  const params = useParams();

  const languageCtx = useLanguage();
  const appStateCtx = useAppState();
  const exceptionCtx = useException();

  const [show, setShow] = useState(false);
  const [album, setAlbum] = useState();
  const bodyRef = useRef();
  const [isWorking, setIsWorking] = useState(false);

  useEffect(() => {
    const refresh = async () => {
      try {
        const matches = new RegExp(/\/app\/albums\/(\w+)(\/join)+/).exec(
          window.location.pathname
        );

        const albumId = matches.length > 1 ? matches[1] : undefined;

        if (!albumId) {
          return;
        }

        if (!appStateCtx.albums.get().find((a) => a._id === albumId)) {
          const album = await appStateCtx.apiServerClient.album.getById(
            albumId
          );
          setShow(true);
          setAlbum(album);
        } else {
          navigate("..");
        }
      } catch (ex) {
        if (errorUtil.isCustomError(ex)) {
          if (
            ex.error.id === "SERVER_ALBUM_NOT_FOUND" ||
            ex.error.id === "SERVER_ALBUM_INVITES_FULL"
          ) {
            setShow(true);
          }
        } else {
          exceptionCtx.handleException(ex);
        }
      }
    };

    refresh();
  }, []);

  // stack
  const [items, setItems] = useState([
    {
      titleLanguageStringId: languageCtx.getIdFromId("Welcome to Fotisima"),
      component: (ctx) => <JoinControl ctx={ctx} />,
    },
  ]);

  useEffect(() => {
    if (bodyRef.current) {
      bodyRef.current.scrollTo({ top: 0 });
    }
  }, [items]);

  const handleBackClicked = () => {
    if (isWorking) return;

    popItem();
  };

  const pushItem = (titleLanguageStringId, component, onBackClicked) => {
    setItems([...items, { titleLanguageStringId, component, onBackClicked }]);
  };

  const popItem = (count = 1) => {
    const items2 = [...items];
    while (count-- > 0) {
      // cannot remove rootItem
      if (items.length > 1) {
        if (items[items.length - 1].onBackClicked) {
          items[items.length - 1].onBackClicked();
        }
        items2.pop();
      }
    }
    setItems(items2);
  };

  const reset = () => {
    const items2 = [...items];
    while (items2.length > 1) {
      if (items2[items2.length - 1].onBackClicked) {
        items2[items2.length - 1].onBackClicked();
      }
      items2.pop();
    }

    setItems(items2);
  };

  const setShowProxy = (show) => {
    if (!show) {
      setTimeout(() => reset(), 500);
    }

    // TODO reset
    setShow(show);
  };

  if (items.length <= 0) return;

  const currentItem = items[items.length - 1];

  const ctx = {
    // stack
    hasParent: items.length > 1,
    currentItem,
    pushItem,
    popItem,
    items,

    album,
    setAlbum,

    isWorking,
    setIsWorking,

    // modal
    show,
    setShow: setShowProxy,
  };

  return (
    <Modal
      show={show}
      setShow={setShowProxy}
      title={
        <>
          {items.length > 1 ? (
            <span onClick={handleBackClicked} className="clickable">
              <FontAwesomeIcon
                style={{
                  margin: -10,
                  margin: -10,
                  padding: 10,
                  padding: 10,
                }}
                icon={faCaretLeft}
              />
              <span className="ms-2">
                {languageCtx.getStringFromId(currentItem.titleLanguageStringId)}
              </span>
            </span>
          ) : (
            <span>
              {languageCtx.getStringFromId(currentItem.titleLanguageStringId)}
            </span>
          )}
        </>
      }
      size="lg"
      closeButton={false}
      backdrop={"static"}
      keyboard={false}
    >
      {currentItem.component(ctx)}
    </Modal>
  );
};
