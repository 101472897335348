import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import validateUtil from "../../../utils/validate.util";

import SelectInput from "../../controls/select.input";
import TextInput from "../../controls/text.input";
import ConfirmModal from "../../controls/confirm2.modal";
import { useMessage } from "../../message.context";
import { useException } from "../../exception.context";
import { useLanguage } from "../../language.context";

import { useAffiliateAppState } from "./appState.context";

export default () => {
  const navigate = useNavigate();
  const parms = useParams();

  const exceptionCtx = useException();
  const messageCtx = useMessage();
  const languageCtx = useLanguage();
  const appStateCtx = useAffiliateAppState();

  const [packages, setPackages] = useState([]);
  const [oldGiftcard, setOldGiftcard] = useState();
  const [newGiftcard, setNewGiftcard] = useState();
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  useEffect(() => {
    const refresh = async () => {
      try {
        let packages =
          await appStateCtx.apiServerClient.affiliate.getPackages();
        packages = packages.filter((p) => p.priceAmountMicros > 0);
        packages.sort((a, b) => a.priceAmountMicros - b.priceAmountMicros);
        setPackages(packages);

        if (parms.giftcardId) {
          // update
          const giftcard =
            await appStateCtx.apiServerClient.affiliate.getGiftcardById(
              parms.giftcardId
            );
          setOldGiftcard(giftcard);
          setNewGiftcard({ ...giftcard });
        } else {
          // new
          setNewGiftcard({ packageId: packages[0]._id, reference: "" });
        }
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };
    refresh();
  }, []);

  const onCloseConfirmDeleteModal = async (success) => {
    setShowConfirmDeleteModal(false);
    if (!success) return;

    try {
      await appStateCtx.apiServerClient.affiliate.deleteGiftcard(
        oldGiftcard._id
      );
      messageCtx.showSuccess(languageCtx.getStringFromId("Giftcard deleted"));
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleCreateClicked = async () => {
    try {
      await appStateCtx.apiServerClient.affiliate.createGiftcard(
        newGiftcard.packageId,
        newGiftcard.reference
      );
      messageCtx.showSuccess(languageCtx.getStringFromId("Giftcard created"));
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleUpdateClicked = async () => {
    try {
      await appStateCtx.apiServerClient.affiliate.updateGiftcard(
        oldGiftcard._id,
        newGiftcard.reference
      );
      messageCtx.showSuccess(languageCtx.getStringFromId("Giftcard updated"));
      navigate("./..");
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleDeleteClicked = () => {
    setShowConfirmDeleteModal(true);
  };

  const isFormOk = () => {
    if (!validateUtil.string.isValid(newGiftcard.packageId)) return false;
    if (!validateUtil.string.isValid(newGiftcard.reference, true, 3))
      return false;

    return true;
  };

  const hasFormChanged = () => {
    return JSON.stringify(oldGiftcard) !== JSON.stringify(newGiftcard);
  };

  const handleCopyClicked = () => {
    navigator.clipboard?.writeText?.(newGiftcard.code);
    messageCtx.showSuccess(
      languageCtx.getStringFromId("codeCopiedToClipboard")
    );
  };

  if (!newGiftcard) return null;

  const _package = packages.find((p) => p._id === newGiftcard.packageId);

  return (
    <div className="page">
      <div className="container">
        <div className="mt-4">
          <h3>
            {oldGiftcard
              ? languageCtx.getStringFromId("Update gift card")
              : languageCtx.getStringFromId("New gift card")}
          </h3>
        </div>

        {oldGiftcard && (
          <div className="mt-2">
            <label className="form-label fw-bold">
              {languageCtx.getStringFromId("Code")}
            </label>
            <div className="input-group">
              <TextInput value={newGiftcard.code} disabled={true} />
              <button className="btn btn-primary" onClick={handleCopyClicked}>
                {languageCtx.getStringFromId("Copy")}
              </button>
            </div>
          </div>
        )}
        <div className="mt-2">
          <label className="form-label fw-bold">
            {languageCtx.getStringFromId("Package")}
          </label>
          <SelectInput
            value={newGiftcard.packageId}
            onChange={(value) =>
              setNewGiftcard({ ...newGiftcard, packageId: value })
            }
            items={packages}
            nameExtractor={(p) =>
              `${languageCtx.getPackageFromId(p.productId).name} - ${
                p.priceAmountMicros / 1000000
              } ${p.currencyCode}`
            }
            valueExtractor={(p) => p._id}
            disabled={!!oldGiftcard}
          />
        </div>
        <div className="mt-2">
          <label className="form-label fw-bold">
            {languageCtx.getStringFromId("Reference")}
          </label>
          <TextInput
            value={newGiftcard.reference}
            onChange={(value) =>
              setNewGiftcard({ ...newGiftcard, reference: value })
            }
          />
        </div>

        <div className="mt-3 btn-toolbar">
          {!oldGiftcard && (
            <button
              className="btn btn-blue"
              onClick={handleCreateClicked}
              disabled={!isFormOk()}
            >
              {languageCtx.getStringFromId("create")}
            </button>
          )}
          {oldGiftcard && (
            <button
              className="btn btn-blue"
              onClick={handleUpdateClicked}
              disabled={!isFormOk() || !hasFormChanged()}
            >
              {languageCtx.getStringFromId("update")}
            </button>
          )}
          {oldGiftcard && !oldGiftcard.usedAt && (
            <button
              className="btn btn-primary-danger ms-1"
              onClick={handleDeleteClicked}
            >
              {languageCtx.getStringFromId("Delete")}
            </button>
          )}
        </div>
      </div>
      <ConfirmModal
        okText={languageCtx.getStringFromId("yes")}
        cancelText={languageCtx.getStringFromId("no")}
        titleText={languageCtx.getStringFromId("Delete giftcard")}
        questionText={languageCtx.getStringFromId(
          "Sure you want to delete giftcard?"
        )}
        show={showConfirmDeleteModal}
        onClose={onCloseConfirmDeleteModal}
      ></ConfirmModal>
    </div>
  );
};
