import Modal from "./modal";

export default ({
  show,
  onClose,
  titleText,
  questionText,
  tipText,
  okText,
  cancelText,
}) => {
  return (
    <Modal
      show={show}
      setShow={onClose}
      title={titleText}
      footer={
        <>
          <button className="btn btn-gray m-1" onClick={() => onClose(false)}>
            {cancelText}
          </button>
          <button className="btn btn-blue m-1" onClick={() => onClose(true)}>
            {okText}
          </button>
        </>
      }
    >
      <div className="app text-center p-3">
        <label className="fw-bold">{questionText}</label>
        {tipText && <div class="mt-3">{tipText}</div>}
      </div>
    </Modal>
  );
};
