import { createContext, useContext, useEffect, useRef, useState } from "react";
import mediaInfoFactory from "mediainfo.js";

import { LocalError, errors } from "../../../../../utils/errors";
import FileServerClient from "../../../../../utils/fileserver.user.client";
import errorUtil from "../../../../../utils/error.util";

import { useAppState } from "../../appState.context";

const uploadQueueContext = createContext();

export const useUploadQueue = () => {
  return useContext(uploadQueueContext);
};

const hasInternet = async () => {
  return true;
  try {
    const response = await fetch("https://www.google.com");
    return true; //response.status == 200; // make sure
  } catch (ex) {
    return false;
  }
};

const canWakeLock = () => "wakeLock" in navigator;

let wakelock;
async function lockWakeState() {
  if (!canWakeLock()) return;
  if (wakelock) return;
  try {
    wakelock = await navigator.wakeLock.request();
    wakelock.addEventListener("release", () => {
      console.log("Screen Wake State Locked:", !wakelock.released);
    });
    console.log("Screen Wake State Locked:", !wakelock.released);
  } catch (e) {
    //console.error("Failed to lock wake state with reason:", e.message);
  }
}

function releaseWakeState() {
  if (wakelock) wakelock.release();
  wakelock = null;
}

function makeReadChunk(file) {
  return async (chunkSize, offset) =>
    new Uint8Array(await file.slice(offset, offset + chunkSize).arrayBuffer());
}

//const sleep = (ms) => new Promise((resolve, reject) => setTimeout(resolve, ms));

export const UPLOADSTATES = {
  PENDING: 1,
  UPLOADING: 2,
  DONE: 3,
};

export const UploadQueueContextProvider = ({ children }) => {
  const appStateCtx = useAppState();

  const mediaInfoRef = useRef();
  const [items, setItems] = useState([]);

  useEffect(() => {
    mediaInfoFactory({
      format: "JSON",
      locateFile: (filename) => {
        return "/assets/" + filename;
      },
    })
      .then((mi) => {
        mediaInfoRef.current = mi;
      })
      .catch((error) => {
        console.error(error);
      });

    return () => {
      if (mediaInfoRef.current) {
        mediaInfoRef.current.close();
      }
    };
  }, []);

  const addItems = (files) => {
    const items = files.map((file) => {
      const item = {
        file,
        progress: 0,
        state: UPLOADSTATES.PENDING,
      };
      return item;
    });

    setItems(items);
    uploadItems(items);
    lockWakeState();
  };

  const clearItems = () => {
    setItems([]);
  };

  const reportItemErrors = (queue, filter, error) => {
    const queue2 = queue.filter(filter);
    queue2.forEach((i) => {
      i.error = error;
      i.state = UPLOADSTATES.DONE;
    });
    setItems(queue2);
  };

  const reportItemError = (queue, item, error) => {
    item.error = error;
    setItems([...queue]);
  };

  const updateItemState = (queue, item, state) => {
    item.state = state;
    setItems([...queue]);
  };

  const reportItemProgress = (queue, item, progress) => {
    item.progress = progress;
    setItems([...queue]);
  };

  const clearItemErrors = () => {
    const queue2 = items.filter((item) => !item.error);
    setItems(queue2);
  };

  const uploadItems = async (items) => {
    while (true) {
      const item = items.find((i) => i.state === UPLOADSTATES.PENDING);

      if (!item) {
        releaseWakeState();
        break;
      }

      updateItemState(items, item, UPLOADSTATES.UPLOADING);
      //await sleep(1400);
      try {
        let metadata;
        try {
          const jsonString = await mediaInfoRef.current.analyzeData(
            item.file.size,
            makeReadChunk(item.file)
          );

          metadata = JSON.parse(jsonString);
        } catch (ex) {
          throw new LocalError(errors.FileInvalid);
        }

        const generalTrack = metadata.media.track.find(
          (t) => t["@type"] === "General"
        );

        if (generalTrack["@type"] !== "General") {
          throw new LocalError(errors.FileInvalid);
        }

        if (generalTrack.Duration) {
          // video
          const seconds = parseFloat(generalTrack.Duration);

          if (seconds > appStateCtx.album.get().package.maxSecondsPerVideo) {
            throw new LocalError(errors.VideoTooLong);
          }
        }

        const server =
          await appStateCtx.apiServerClient.server.getAvailableFileserver();

        const token = appStateCtx.apiServerClient.getToken();

        const fileServerClient = new FileServerClient(server.origin, token);

        await fileServerClient.media.uploadFile(
          appStateCtx.album.get()._id,
          "browser",
          item.file,
          server._id,
          (progress) => reportItemProgress(items, item, progress * 100)
        );
      } catch (ex) {
        //console.error(ex);
        if (ex.status === 503) {
          // this includes not connecting to fileserver/apiserver
          ex = new LocalError(errors.BadConnection);
        }
        // TODO Timeout
        // TODO network cable removed
        if (!hasInternet()) {
          // skip rest of queue
          reportItemErrors(items, (i) => i.state !== UPLOADSTATES.DONE, {
            id: errors.NoInternet.id,
          });
        } else if (errorUtil.isCustomError(ex)) {
          // server response
          if (["SERVER_MEDIA_ALREADY_EXIST"].includes(ex.error.id)) {
            // skip file
          } else if (
            [errors.FileInvalid.id, errors.VideoTooLong.id].includes(
              ex.error.id
            )
          ) {
            reportItemError(items, item, { id: ex.error.id });
          } else {
            // skip rest of images
            reportItemErrors(items, (i) => i.state !== UPLOADSTATES.DONE, {
              id: ex.error.id,
            });
          }
        } else {
          reportItemError(items, item, { ex });
        }
      } finally {
        updateItemState(items, item, UPLOADSTATES.DONE);
      }
    }

    appStateCtx.medias.refreshFromChanges();
  };

  const initialValue = {
    items,
    add: addItems,
    clear: clearItems,
    clearErrors: clearItemErrors,
  };

  return (
    <uploadQueueContext.Provider value={initialValue}>
      {children}
    </uploadQueueContext.Provider>
  );
};
