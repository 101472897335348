import { DateTime } from "luxon";
import { Calendar } from "@hassanmojab/react-modern-calendar-datepicker";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";

import colors from "../../../../../../utils/colors";

import { useLanguage } from "../../../../../language.context";

export default ({ ctx, date, setDate }) => {
  const languageCtx = useLanguage();

  const locale = {
    months: languageCtx.getFormattingFromId("months").map((m) => m.name),

    weekDays: languageCtx.getFormattingFromId("weekDays").map((m) => ({
      name: m.name,
      short: m.name.substring(0, 1),
      isWeekend: m.isWeekend,
    })),

    weekStartingIndex: languageCtx.getFormattingFromId("firstDayOfWeek"),

    getToday(gregorainTodayObject) {
      return gregorainTodayObject;
    },

    toNativeDate(date) {
      return new Date(date.year, date.month - 1, date.day);
    },

    getMonthLength(date) {
      return new Date(date.year, date.month, 0).getDate();
    },

    // return a transformed digit to your locale
    transformDigit(digit) {
      return digit;
    },

    // texts in the date picker
    nextMonth: languageCtx.getStringFromId("Next month"),
    previousMonth: languageCtx.getStringFromId("Previous month"),
    openMonthSelector: languageCtx.getStringFromId("Open month selector"),
    openYearSelector: languageCtx.getStringFromId("Open year selector"),
    closeMonthSelector: languageCtx.getStringFromId("Close month selector"),
    closeYearSelector: languageCtx.getStringFromId("Close year selector"),
    defaultPlaceholder: languageCtx.getStringFromId("select") + "...",

    // for input range value
    from: languageCtx.getStringFromId("from"),
    to: languageCtx.getStringFromId("to"),

    // used for input value when multi dates are selected
    digitSeparator: languageCtx.getFormattingFromId("digitSeperator"),

    // if your provide -2 for example, year will be 2 digited
    yearLetterSkip: 0,

    // is your language rtl or ltr?
    isRtl: false,
  };

  return (
    <div className="text-center p-4">
      <Calendar
        value={DateTime.fromISO(date).toObject()}
        onChange={(d) => {
          setDate(DateTime.fromObject(d).toISODate());

          ctx.popItem();
        }}
        minimumDate={DateTime.now().toObject()}
        colorPrimary={colors.blue}
        locale={locale}
        calendarClassName="d-inline-block"
        style={{ boxShadow: "initial" }}
      />
    </div>
  );
};
