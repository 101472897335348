import React from "react";

import settings from "../../../../../../../utils/settings";

import PayControl from "../../../../../../controls/pay.control";

import { useAppState } from "../../../../appState.context";

export default ({ ctx }) => {
  const appStateCtx = useAppState();

  const handleCreateClientSecret = async () => {
    const clientSecret =
      await appStateCtx.apiServerClient.stripe.createUpgradeAlbumPaymentIntent(
        appStateCtx.album.get()._id,
        ctx.changePackage.package._id
      );

    return clientSecret;
  };

  return (
    <PayControl
      onCreateClientSecret={handleCreateClientSecret}
      isWorking={ctx.isWorking}
      setIsWorking={ctx.setIsWorking}
      currency={ctx.changePackage.package.currencyCode}
      price={ctx.changePackage.package.priceAmountMicros / 1000000}
      paymentCompletionUrl={settings.stripe.returnUrl}
    />
  );
};
