import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useLanguage } from "../language.context";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

export default ({ children }) => {
  const languageCtx = useLanguage();

  const location = useLocation();
  const [count, setCount] = useState(0);

  useEffect(() => {
    // execute on location change
    setCount(count + 1);
  }, [location]);

  const childArray = React.Children.toArray(children);
  const preChildren = childArray.filter(
    (c) => c.type.displayName === "NavLink"
  );
  const postChildren = childArray.filter(
    (c) => c.type.displayName !== "NavLink"
  );

  const handleLanguageClicked = async (language) => {
    await languageCtx.setLanguageAsync(language.code);
  };

  return (
    <div>
      <Navbar fixed="top" expand="lg" className="menu bg-light">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img
              src="/assets/images/fotisima-v2.svg"
              style={{ height: "3.8rem" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto"></Nav>
            <Nav className="align-items-center">
              {preChildren}

              <NavDropdown
                title={<FontAwesomeIcon icon={faGlobe} />}
                align="end"
                className="e-caret-hide"
                id="navbarScrollingDropdown"
              >
                {languageCtx.languages.map((l) => {
                  return (
                    <NavDropdown.Item
                      key={l.code}
                      onClick={() => handleLanguageClicked(l)}
                    >
                      {l.description}
                    </NavDropdown.Item>
                  );
                })}
              </NavDropdown>
              {postChildren}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};
