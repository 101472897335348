import { useLanguage } from "../../../../language.context";

export default ({ ctx }) => {
  const languageCtx = useLanguage();

  return (
    <div className="p-3 text-center">
      <div className="fs-2 mt-3">
        {languageCtx.getStringFromId("How to join")}
      </div>
      <div className="mt-2">
        {languageCtx.getStringFromId("You can join an album in 2 ways:")}
      </div>

      <div className="mt-4">
        <div className="fw-bold">
          {languageCtx.getStringFromId("Using QR Code")}
        </div>
        <div className="mt-2">
          {languageCtx.getStringFromId(
            "On your device, scan the album QR code with your camera"
          )}
        </div>
        <div className="mt-3">
          <div className="fw-bold">
            {languageCtx.getStringFromId("Using Link")}
          </div>
          <div className="mt-2 mb-4">
            {languageCtx.getStringFromId(
              "Ask the album owner or an album member for the album link"
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
