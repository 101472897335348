import { useState } from "react";
import { useNavigate } from "react-router";

import colors from "../../../../../utils/colors";
import albumUtil from "../../../../../utils/album.util";
import userUtil from "../../../../../utils/user.util";

import { useException } from "../../../../exception.context";
import { useLanguage } from "../../../../language.context";
import { useMessage } from "../../../../message.context";

import { useAppState } from "../../appState.context";

export default ({ ctx }) => {
  const navigate = useNavigate();

  const languageCtx = useLanguage();
  const appStateCtx = useAppState();
  const messageCtx = useMessage();
  const exceptionCtx = useException();

  const [name, setName] = useState("");

  const handleSignupAsGuestAndJoinClicked = async () => {
    try {
      await appStateCtx.user.signupAsGuestAndJoin(
        name,
        ctx.album._id,
        languageCtx.getPrivacyPolicy()._id,
        languageCtx.getTermsOfService()._id
      );

      messageCtx.showSuccess(
        languageCtx.getStringFromId("youCreatedAUserAndJoinedAnAlbum")
      );
      ctx.setShow(false);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleJoinClicked = async () => {
    try {
      await appStateCtx.album.join(ctx.album._id);

      messageCtx.showSuccess(languageCtx.getStringFromId("youJoinedAnAlbum"));
      ctx.setShow(false);
    } catch (ex) {
      exceptionCtx.handleException(ex);
    }
  };

  const handleCancelClicked = async () => {
    ctx.setShow(false);
    if (appStateCtx.user.get()) {
      navigate("/app/albums");
    } else {
      navigate("/");
    }
  };

  const handleCloseClicked = async () => {
    ctx.setShow(false);
    if (appStateCtx.user.get()) {
      navigate("/app/albums");
    } else {
      navigate("/");
    }
  };

  const isFormOk = () => {
    if (!name) return false;
    if (name.trim().length < 1) return false;

    return true;
  };

  if (!ctx.show) return null;

  return (
    <div className="p-4">
      {(!albumUtil.isAlbum(ctx.album) ||
        albumUtil.isUsersFull(ctx.album) ||
        albumUtil.isMediaDeleted(ctx.album)) && (
        <div className="text-center">
          <div className="display-6">
            {languageCtx.getStringFromId("Cannot join the album")}
          </div>
          <div className="mt-3">
            {albumUtil.isUsersFull(ctx.album) && (
              <div>{languageCtx.getStringFromId("Too many invites")}</div>
            )}
            {albumUtil.isMediaDeleted(ctx.album) && (
              <div>{languageCtx.getStringFromId("Album closed")}</div>
            )}
            {!albumUtil.isAlbum(ctx.album) && (
              <div>{languageCtx.getStringFromId("Album does not exist")}</div>
            )}
          </div>
          <div className="mt-4">
            <button className="btn btn-blue" onClick={handleCloseClicked}>
              {languageCtx.getStringFromId("close")}
            </button>
          </div>
        </div>
      )}
      {albumUtil.isAlbum(ctx.album) &&
        !albumUtil.isUsersFull(ctx.album) &&
        !albumUtil.isMediaDeleted(ctx.album) && (
          <div className="text-center">
            <div className="display-6">
              {languageCtx.getStringFromId("Join the album")}
            </div>
            <div className="display-6 fw-bold mt-3">{ctx.album.name}</div>

            <div className="mt-3 cover">
              {ctx.album.cover && (
                <img
                  src={ctx.album.cover.uri}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit:
                      ctx.album.cover?.source === "db" ? "contain" : "cover",
                  }}
                />
              )}
            </div>

            <div className="mt-4">
              {languageCtx.getStringFromId(
                "Help collect photos and videos to this event"
              )}
            </div>
            {ctx.album.isDemo && (
              <div className="mt-4 fst-italic">
                {languageCtx.getStringFromId(
                  "This is a demo album and the content could be deleted at any time"
                )}
              </div>
            )}
            {!userUtil.isUser(appStateCtx.user.get()) && (
              <div>
                <div className="mt-4">
                  {languageCtx.getStringFromId("What username would you like?")}
                </div>
                <div className="mt-2">
                  <input
                    className="form-control text-center"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div
                  className="mt-2"
                  style={{
                    fontSize: 14,
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {languageCtx.getStringFromId("byContinuingYouAcceptOur")}{" "}
                  <a
                    href={languageCtx.getPrivacyPolicy().url}
                    target="_blank"
                    referrerPolicy="no-referrer"
                    style={{ color: colors.darkblue }}
                  >
                    {languageCtx.getStringFromId("privacyPolicy")}
                  </a>{" "}
                  {languageCtx.getStringFromId("and our")}{" "}
                  <a
                    href={languageCtx.getTermsOfService().url}
                    target="_blank"
                    referrerPolicy="no-referrer"
                    style={{ color: colors.darkblue }}
                  >
                    {languageCtx.getStringFromId("termsOfService")}
                  </a>
                </div>
              </div>
            )}
            <div className="mt-3">
              <button className="btn btn-gray" onClick={handleCancelClicked}>
                {languageCtx.getStringFromId("Cancel")}
              </button>
              {!appStateCtx.user.get() && (
                <button
                  className="btn btn-blue ms-1"
                  onClick={handleSignupAsGuestAndJoinClicked}
                  disabled={!isFormOk()}
                >
                  {languageCtx.getStringFromId("continue")}
                </button>
              )}
              {appStateCtx.user.get() && (
                <button
                  className="btn btn-blue ms-1"
                  onClick={handleJoinClicked}
                >
                  {languageCtx.getStringFromId("Join now")}
                </button>
              )}
            </div>
          </div>
        )}
    </div>
  );
};
