import React, { createContext, useEffect, useContext, useState } from "react";

import ApiServerClient from "../utils/apiserver.user.client";
import localStorageUtil from "../utils/localStorage.util";

const languageContext = createContext();

export const useLanguage = () => {
  return useContext(languageContext);
};

const getBrowserLanguage = () => {
  let locale = window.navigator.language;
  if (!locale) {
    locale = "en";
  }

  const language = locale.split("-")[0];
  return language;
};

const getUrlLanguage = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get("la");
};

const getInitialLanguageCode = () => {
  let language =
    getUrlLanguage() ||
    localStorageUtil.get("language") ||
    getBrowserLanguage() ||
    "en";

  return language;
};

export const LanguageContextProvider = ({ children }) => {
  const [language, setLanguage] = useState();
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    const refresh = async () => {
      try {
        const apiServerClient = new ApiServerClient();
        const languages = await apiServerClient.language.get();

        const languageCode = getInitialLanguageCode();

        setLanguages(languages);
        await setLanguageAsync(languageCode);
      } catch (ex) {}
    };

    refresh();
  }, []);

  const getStringFromId = (id) => {
    if (!language) return "";
    if (language.strings[id] === undefined) {
      console.error(`${id} string not found`);
      return "";
    }
    return language.strings[id];
  };

  const getIdFromId = (id) => id;

  const getPackageFromId = (id) => {
    if (!language) return "";
    if (language.packages[id] === undefined) {
      console.error(`${id} package not found`);
      return "";
    }
    return language.packages[id];
  };

  const getFormattingFromId = (id) => {
    if (!language) return "";

    if (language.formattings[id] === undefined) {
      console.error(`${id} formatting not found`);
      return "";
    }
    return language.formattings[id];
  };

  const getTermsOfService = () => {
    if (!language) return "";

    return language.termsofservice;
  };

  const getPrivacyPolicy = () => {
    if (!language) return "";

    return language.privacypolicy;
  };

  const getErrorFromId = (id) => {
    if (!language) return "";
    if (language.errors2[id] === undefined) {
      console.error(`${id} error not found`);
      return "";
    }
    return language.errors2[id];
  };

  const setLanguageAsync = async (languageCode) => {
    const apiServerClient = new ApiServerClient();

    // 2 next lines should be removed, since it is taken take of in getByCode on the server side
    // const languages = await apiServerClient.language.get();
    // if (!languages.some((l) => l.code === languageCode)) languageCode = "en";
    const language = await apiServerClient.language.getByCode(languageCode);

    localStorageUtil.set("language", language.code);

    setLanguage(language);
  };

  const noTranslate = (txt) => txt;

  const initialValue = {
    language,
    setLanguageAsync,
    languages,
    getTermsOfService,
    getPrivacyPolicy,
    getStringFromId,
    getFormattingFromId,
    getErrorFromId,
    getPackageFromId,
    noTranslate,
    getIdFromId,
  };

  if (!language) return null;

  return (
    <languageContext.Provider value={initialValue}>
      {children}
    </languageContext.Provider>
  );
};
