import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useException } from "../../exception.context";
import { useLanguage } from "../../language.context";

import { useAffiliateAppState } from "./appState.context";

export default () => {
  const navigate = useNavigate();

  const languageCtx = useLanguage();
  const exceptionCtx = useException();
  const appStateCtx = useAffiliateAppState();

  const [status, setStatus] = useState("processing");

  useEffect(() => {
    const refresh = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const clientSecret = urlParams.get("payment_intent_client_secret");

        await appStateCtx.apiServerClient.affiliate.getInvoiceByClientSecret(
          clientSecret
        );
        setStatus("success");
      } catch (ex) {
        exceptionCtx.handleException(ex);
        setStatus("error");
      }
    };
    refresh();
  }, []);

  const handleViewInvoicesClicked = () => {
    navigate("/affiliate/giftcards/invoices");
  };

  return (
    <div className="position-absolute top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-center align-items-center text-center ">
      <div className="container">
        {status === "processing" && (
          <div>
            <div className="fs-1 fw-bold">
              {languageCtx.getStringFromId("Processing payment")}
            </div>
            <div className="fs-2 mt-2">
              {languageCtx.getStringFromId("Please wait")}
            </div>
          </div>
        )}
        {status === "success" && (
          <div>
            <div className="fs-1 fw-bold">
              {languageCtx.getStringFromId("success")}
            </div>
            <div className="fs-2 mt-2">
              {languageCtx.getStringFromId("Invoice paid")}
            </div>
            <div className="mt-3">
              <button
                className="btn btn-blue"
                onClick={handleViewInvoicesClicked}
              >
                {languageCtx.getStringFromId("View invoices")}
              </button>
            </div>
          </div>
        )}
        {status === "error" && (
          <div>
            <div className="fs-1 fw-bold">
              {languageCtx.getStringFromId("Failure")}
            </div>
            <div className="fs-2 mt-2">
              {languageCtx.getStringFromId(
                "Payment failed. If problem persist, contact us!"
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
