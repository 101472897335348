import { useLanguage } from "../../../../../../language.context";

import ConfirmdeleteaccountControl from "./confirmdeleteaccount.control";

export default ({ ctx }) => {
  const languageCtx = useLanguage();

  const handleDeletePressed = async () => {
    ctx.pushItem(languageCtx.getIdFromId("Confirm delete account"), (ctx) => (
      <ConfirmdeleteaccountControl ctx={ctx} />
    ));
  };

  return (
    <div className="p-4">
      <div className="fs-2 lh-sm">
        {languageCtx.getStringFromId(
          "Delete your account and remove all you content from Fotisima?"
        )}
      </div>
      <div className="mt-3">
        <div>
          <span>
            {languageCtx.getStringFromId("By continuing, you confirm")}
          </span>
        </div>
        <div className="mt-2">
          <span className="fw-bold">• </span>
          <span className="fw-bold">
            {languageCtx.getStringFromId(
              "deletingYourFotisimaAccountIsPermanent"
            )}
          </span>
          <span>
            {languageCtx.getStringFromId(
              "cannotBeUndoneAndWillLogYouOutOfThisApplication"
            )}
          </span>
        </div>
        <div>
          <span className="fw-bold">• </span>
          <span>
            {languageCtx.getStringFromId(
              "youWillBeIrrevocablyRemovedFromAnyAlbumsThatYouAreAMemberOf"
            )}
          </span>
        </div>
      </div>
      <button
        className="btn btn-blue w-100 m-0 mt-4"
        onClick={handleDeletePressed}
      >
        {languageCtx.getStringFromId("deleteMyAccount")}
      </button>
    </div>
  );
};
