import { DateTime } from "luxon";

import colors from "../../../../../../utils/colors";

import { useLanguage } from "../../../../../language.context";

import SelectDateFromCalenderControl from "./selectDateFromCalender.control";
import SelectPackageControl from "./selectPackage.control";

export default ({ ctx }) => {
  const languageCtx = useLanguage();

  const isFormOk = () => {
    if (!ctx.createAlbum.startDate) return false;

    return true;
  };

  const handleContinuePressed = () => {
    ctx.pushItem(languageCtx.getIdFromId("Create album"), (ctx) => (
      <SelectPackageControl ctx={ctx} />
    ));
  };

  const handleStartDateClicked = () => {
    ctx.pushItem(languageCtx.getIdFromId("Select date"), (ctx) => (
      <SelectDateFromCalenderControl
        ctx={ctx}
        date={ctx.createAlbum.startDate}
        setDate={ctx.createAlbum.setStartDate}
      />
    ));
  };

  return (
    <div className="text-center p-4">
      <div className="fs-2 lh-sm">
        {languageCtx.getStringFromId("whatDateYouWantYourAlbumToStart")}
      </div>

      <div className="mt-4">
        <input
          type="text"
          className="form-control text-center form-control-lg clickable"
          value={DateTime.fromISO(ctx.createAlbum.startDate).toFormat(
            languageCtx.getFormattingFromId("dateLong")
          )}
          onClick={handleStartDateClicked}
          readOnly
        />
        <div className="mt-4">
          <div
            style={{
              color: colors.red,
            }}
          >
            {ctx.createAlbum.startDate <= DateTime.now().toISODate()
              ? languageCtx.getStringFromId("Your album will start immediatly")
              : ""}
          </div>
          {ctx.createAlbum.startDate > DateTime.now().toISODate() && (
            <div className="mt-3">
              {`${languageCtx.getStringFromId(
                "tip"
              )}: ${languageCtx.getStringFromId(
                "You can change start date under album settings"
              )}`}
            </div>
          )}
        </div>
      </div>

      <div className="mt-3">
        <button
          disabled={!isFormOk()}
          onClick={handleContinuePressed}
          className="btn btn-blue"
        >
          {languageCtx.getStringFromId("continue")}
        </button>
      </div>
    </div>
  );
};
