import { useEffect, useState } from "react";
import { DateTime } from "luxon";

import { useException } from "../../exception.context";
import { useLanguage } from "../../language.context";
import TableControl from "../../controls/table.control";

import { useAffiliateAppState } from "./appState.context";

export default () => {
  const languageCtx = useLanguage();
  const exceptionCtx = useException();
  const appStateCtx = useAffiliateAppState();

  const [charges, setCharges] = useState([]);
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    const refresh = async () => {
      try {
        const charges =
          await appStateCtx.apiServerClient.affiliate.getChargesByMonth(
            DateTime.now().toISODate().substring(0, 7)
          );

        charges.sort((a, b) => a.createdAt.localeCompare(b.createdAt));

        const balance = charges.reduce((a, b) => a + b.amountMicros || 0, 0);
        setBalance(balance);
        setCharges(charges);
      } catch (ex) {
        exceptionCtx.handleException(ex);
      }
    };

    refresh();
  }, []);

  return (
    <div className="page">
      <div className="container">
        <div className="mt-4">
          <h3>{languageCtx.getStringFromId("Current month charges")}</h3>
        </div>

        <div className="mt-3">
          <TableControl
            columns={[
              {
                header: languageCtx.getStringFromId("date"),
                valueExtractor: (t) =>
                  DateTime.fromISO(t.createdAt).toISODate(),
              },
              {
                header: languageCtx.getStringFromId("Giftcard code"),
                valueExtractor: (t) => t.giftcard?.code,
              },
              {
                header: languageCtx.getStringFromId("Reference"),
                valueExtractor: (t) => t.giftcard?.reference,
              },
              {
                header: languageCtx.getStringFromId("Amount"),
                className: "text-end",
                valueExtractor: (t) =>
                  `${(t.amountMicros / 1000000).toFixed(2)} ${
                    appStateCtx.affiliate.get().currencyCode
                  }`,
              },
            ]}
            rows={charges}
            keyExtractor={(t) => t._id}
            footerColumns={[
              {
                colspan: 3,
                valueExtractor: (f) => "",
              },
              {
                className: "text-end",
                valueExtractor: (f) =>
                  `${(f.balance / 1000000).toFixed(2)} ${
                    appStateCtx.affiliate.get().currencyCode
                  }`,
              },
            ]}
            footerKeyExtractor={(r) => r.balance}
            footerRows={[{ balance }]}
          />
        </div>
      </div>
    </div>
  );
};
