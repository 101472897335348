import {
  faFileLines,
  faImage,
  faMobileScreen,
  faShieldAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import deviceUtil from "../../../../../../utils/device.util";

import { useLanguage } from "../../../../../language.context";

import MenuControl from "../../../../../controls/buttonmenu.control";

import AccountControl from "./account/control";
import EditphotosorderbyControl from "./editphotosorderby.control";
import PrivacyControl from "./privacy.control";
import TermsofserviceControl from "./termsofservice.control";
import AddtohomescreenControl from "./addtohomescreen.control";

export default ({ ctx }) => {
  const languageCtx = useLanguage();

  const items = [
    {
      titleLanguageStringId: languageCtx.getIdFromId("account"),
      icon: faUser,
      onClick: () => {
        ctx.pushItem(languageCtx.getIdFromId("account"), (ctx) => (
          <AccountControl ctx={ctx} />
        ));
      },
    },
    {
      titleLanguageStringId: languageCtx.getIdFromId("Photos order by"),
      icon: faImage,
      onClick: () => {
        ctx.pushItem(languageCtx.getIdFromId("Photos order by"), (ctx) => (
          <EditphotosorderbyControl ctx={ctx} />
        ));
      },
    },
    {
      titleLanguageStringId: languageCtx.getIdFromId("PrivacyPolicy"),
      icon: faShieldAlt,
      onClick: () => {
        ctx.pushItem(languageCtx.getIdFromId("PrivacyPolicy"), (ctx) => (
          <PrivacyControl ctx={ctx} />
        ));
      },
    },
    {
      titleLanguageStringId: languageCtx.getIdFromId("TermsOfService"),
      icon: faFileLines,
      onClick: () => {
        ctx.pushItem(languageCtx.getIdFromId("TermsOfService"), (ctx) => (
          <TermsofserviceControl ctx={ctx} />
        ));
      },
    },
    {
      titleLanguageStringId: languageCtx.getIdFromId("Add to home screen"),
      icon: faMobileScreen,
      onClick: () => {
        ctx.pushItem(languageCtx.getIdFromId("Add to home screen"), (ctx) => (
          <AddtohomescreenControl ctx={ctx} />
        ));
      },
      visible: () => deviceUtil.isIOS || deviceUtil.isAndroid,
    },
  ];

  return <MenuControl items={items} />;
};
